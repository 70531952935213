import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import productview from "../images/product-view.jpg";
import MemberTable from "../Customer/MemberTable";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import AddIngredientsMeasurements from "./AddIngredientMeasurement";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StoreNewItemModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [edit, setEdit] = React.useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const changeEdit = () => setEdit((edit) => !edit);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleOpenEdit = () => setShow(true);
  const handleCloseAdd = () => setAddOpen(false);

  const data = [
    [
      "12/03/2023",
      "474858",
      "Accepted",
      "₹ 22890",
      "Pending",
      "Cheque",
      "12345678",
      "12/03/2023",
    ],
  ];

  const managementColumns = [
    {
      name: "Ingredient Name",
      options: { filterOptions: { fullWidth: true } },
    },
    "UOM",
    "No. of Units",
    "Required Qty",
  ];
  const managementdata = [["Everest Biryani Masala", "gm", "150gms", "20gms"]];

  const managementOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const ref4 = useRef();

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content retail-content sni-content"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ITEM ID - 538838
            </Typography>
            <Box className="sub-modal-header">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={2}
                onClick={changeEdit}
                className="retail-btn  mr-2"
              >
                {edit ? "Edit" : "Save"}
              </Button>
              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>
          <Divider></Divider>
          <Box className="modal-body">
            <Grid className="add_new_item">
              <Card>
                <CardContent className={edit ? "disable" : ""}>
                  {/* <Typography component="h4" className="sni-title">
                    SKU ID - 454989
                  </Typography> */}

                  <Box className="images d-flex">
                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={productview} />
                    </Box>

                    {/* <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={dal3} />
                    </Box>

                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={dal4} />
                    </Box> */}
                  </Box>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      component="label"
                      className="btn-upload"
                    >
                      Upload image
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        disabled={edit ? true : false}
                      />
                    </Button>
                  </Box>

                  <Grid container>
                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">Item Name</Typography>
                          <OutlinedInput
                            placeholder="Item Name"
                            value="Chana Dal"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <FormControlLabel
                          className="remove-tick"
                          control={<Switch defaultChecked />}
                          label="Non Veg"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      mt={1}
                      mb={3}
                      className="textarea-div"
                    >
                      <Grid item xs={12} md={4}>
                        <FormControl className="form_control" fullWidth>
                          <Typography component="label">Segment</Typography>
                          <OutlinedInput placeholder="Segment" value="" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl className="form_control" fullWidth>
                          <Typography component="label">Sub Segment</Typography>
                          <OutlinedInput placeholder="Sub Segment" value="" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl className="w-100">
                          <Typography component="label">Description</Typography>
                          <Box component="textarea" rows={5} placeholder="" />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Typography component="h4" className="sub_title" mt={2}>
                      UOM MEASUREMENT
                    </Typography>
                    <Grid container spacing={2} marginY={1} mb={2}>
                      <Grid item md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">Metric</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="select"
                          >
                            <MenuItem value="select">Please Select</MenuItem>
                            <MenuItem value="weight">Weight</MenuItem>
                            <MenuItem value="volume">Volume</MenuItem>
                            <MenuItem value="count">Count</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">UOM</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            defaultValue="select"
                          >
                            <MenuItem value="select">Please Select</MenuItem>
                            <MenuItem value="weight">Kg</MenuItem>
                            <MenuItem value="volume">Litre</MenuItem>
                            <MenuItem value="count">Ounces</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">No of Units</Typography>
                          <OutlinedInput />
                        </FormControl>
                      </Grid>
                      <Grid item md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">Cost Price</Typography>
                          <OutlinedInput />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      className="w-100"
                    >
                      <Typography component="h4" className="sub_title" mt={2}>
                        INGREDIENTS MEASUREMENT
                      </Typography>
                      <Button
                        className="btn btn-outline-primary"
                        onClick={() => ref4.current.log()}
                      >
                        Add
                      </Button>
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <MemberTable
                          columns={managementColumns}
                          options={managementOptions}
                          data={managementdata}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
          <AddIngredientsMeasurements ref={ref4} />
        </Box>
      </Modal>
    </div>
  );
});

export default StoreNewItemModal;
