import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import rupee from "../images/rupee.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import pickup from "../images/pickup1.svg";
import truck from "../images/truck.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import reddot from "../images/reddot.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddMovieModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const subEdit = () => {
    setEdit((edit) => !edit);
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const skuColumns = [
    { name: "SKU ID", options: { filterOptions: { fullWidth: true } } },
    "SKU NAME",
    "QTY",
    "PRICE",
  ];

  const pcData = [
    ["#98759", "Rice", "01", "Rs. 300"],
    ["", "", "Sub Total", "Rs. 300"],
    ["", "", "Discount", "Rs. 100"],
    ["", "", "Taxes", "Rs. 200"],
    ["", "", "Grand Total", "Rs. 400"],
  ];

  const OrderHistoryData = [["#98759", "28-07-2023", "Rs.300", "Delivered"]];
  const OrderHistoryColumns = ["Order ID", "Date", "Amount", "Status"];
  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content order-content">
          <Box className="modal-header p-15">
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="m-heading"
              component="h5"
            >
              Edit Movie
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid container spacing={2} mt={1} paddingRight={2} mb={2}>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Movie Name*</Typography>
                <OutlinedInput placeholder="Enter Name" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Language*</Typography>
                <OutlinedInput placeholder="Enter Language" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Censor Rating*</Typography>
                <OutlinedInput placeholder="Enter Rating" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Category*</Typography>
                <OutlinedInput placeholder="Enter Category" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Director*</Typography>
                <OutlinedInput placeholder="Enter Director" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Cast*</Typography>
                <OutlinedInput placeholder="Enter Cast" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Synopsis*</Typography>
                <OutlinedInput placeholder="Enter Synopsis" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Duration*</Typography>
                <OutlinedInput placeholder="Enter Duration" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Release Date*</Typography>
                <OutlinedInput placeholder="Date" type="date" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">End Date*</Typography>
                <OutlinedInput placeholder="Enter Date" type="date" />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2} paddingRight={2} mt={1}>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Distributor Name*</Typography>
                <OutlinedInput placeholder="Enter Distributor Name" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Distributor Address*</Typography>
                <OutlinedInput placeholder="Enter Distributor Address" />
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Distributor GST No*</Typography>
                <OutlinedInput placeholder="Enter GST NO" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Bank Name*</Typography>
                <OutlinedInput placeholder="Enter Bank Name" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Bank Branch*</Typography>
                <OutlinedInput placeholder="Enter Branch" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">IFSC Code*</Typography>
                <OutlinedInput placeholder="Enter IFSC" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Account Number*</Typography>
                <OutlinedInput placeholder="Enter Account Number" />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Account Holder Name*</Typography>
                <OutlinedInput placeholder="Enter Account Holder Name" />
              </FormControl>
            </Grid>
          </Grid>
          <Box className="p-15 flex-end">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={2}
              className="retail-btn"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default AddMovieModal;
