import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import mveimg from "../images/kushi.jpg";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
} from "@mui/material";
// import test from "../src/images/test.jpg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const TicketPaymentModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal"
      >
        <Box sx={{ ...style }} className="profile-modal tickets-payment">
          {/* <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                Profile
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider> */}

          <Grid container spacing={2} className="p-15">
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Box
                    component="img"
                    src={mveimg}
                    className="mve-img-tickets"
                  />
                </Grid>
                <Grid item md={8} marginLeft={1}>
                  <Typography component="p" className="mve-name-ticket">
                    Kushi
                  </Typography>
                  <Typography component="p" className="mve-theatre-location">
                    PVR, Forum Mall, Kormangala
                  </Typography>
                  <Typography component="p" className="mve-theatre-location">
                    Friday, 29 November
                  </Typography>
                  <Typography component="p" className="mve-theatre-location">
                    4:00 PM
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Box className="ticket-details-block">
                    <Typography component="p" className="ticket-details">
                      CLASSIC - C2, C3
                      <br /> ( 2 Tickets ) SCREEN 6
                    </Typography>
                    <Typography component="p" className="ticket-price">
                      Rs. 1600.00
                    </Typography>
                  </Box>
                  <Box className="ticket-details-block">
                    <Typography component="p" className="ticket-details">
                      Convenience fees
                    </Typography>
                    <Typography component="p" className="ticket-price">
                      Rs. 141.60
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="dashed-lines"></Divider>
          <Grid container spacing={2} marginY={2}>
            <Grid item md={12} textAlign={"center"}>
              <Typography component="p" className="tickets-total-amount">
                Total Amount: Rs. 1741.60
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item md={12} textAlign={"center"}>
              <Button className="pay-btn">Pay Now</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
});

export default TicketPaymentModal;
