import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import product4 from "../images/product-4.jpg";
import productview from "../images/product-view.jpg";
import cb from "../images/cb.jpg";
import mb from "../images/mb.jpg";
import ViewSuppliesModal from "./ViewSuppliesModal";
import gm from "../images/gm.jpg";
import bm from "../images/bm.jpg";
import cp from "../images/cp.jpg";
import SupplyAddIngredientsModal from "../FoodCourt/SupplyAddIngredientModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StockManagement() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memeberColumns = [
    "",
    { name: "Ingredient ID", options: { filterOptions: { fullWidth: true } } },
    "Segment",
    "Sub Segment",
    "Ingredient Name",
    "Qty",
    "Expiry Date",
    "Cost Price",
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    page: 1,
  };

  const data = [
    [
      <img src={gm} />,
      "#58838",
      "Masala",
      "Garam Masala",
      "Everest Garam Masala",
      "250gm",
      "21 Aug 2024",
      "₹120",
    ],
    [
      <img src={bm} />,
      "#63892",
      "Masala",
      "Biryani Masala",
      "Everest Biryani Masala",
      "250gm",
      "21 Aug 2024",
      "₹180",
    ],
    [
      <img src={cp} />,
      "#93873",
      "Masala",
      "Corrainder Masala",
      "Everest Corrainder Masala",
      "250gm",
      "21 Aug 2024",
      "₹150",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link> */}
                    <Link underline="hover" color="inherit" href="#">
                      Menu
                    </Link>
                    <Typography color="text.primary">
                      Supplies Management
                    </Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <Select
                      labelId="demo-isolation-label"
                      id="isolation-select"
                      className="select"
                      // onChange={handleSelectOxygen}
                      // input={<OutlinedInput />}
                      defaultValue="Suppliers"
                    >
                      <MenuItem value="Suppliers">Suppliers</MenuItem>
                      <MenuItem value="visakhapatnam">S.S.M Kirana</MenuItem>
                      <MenuItem value="vijayawada">
                        Sri Raghavendra Super Market
                      </MenuItem>
                      <MenuItem value="vijayawada">
                        Suryanarayana Kirana and General
                      </MenuItem>
                      <MenuItem value="vijayawada">
                        SKML Kirana and Genral
                      </MenuItem>
                    </Select>
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box mb={2} className="sm-selects">
                          <Typography component="h4" className="sub_title">
                            SUPPLIES MANAGEMENT
                          </Typography>
                          <Box>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="orange"
                            >
                              <MenuItem value="orange">Segment</MenuItem>
                              <MenuItem value="visakhapatnam">Rice</MenuItem>
                              <MenuItem value="vijayawada">Masala</MenuItem>
                            </Select>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="segment"
                            >
                              <MenuItem value="segment">Sub Segments</MenuItem>
                              <MenuItem value="visakhapatnam">Lalitha</MenuItem>
                              <MenuItem value="vijayawada">Kohinoor</MenuItem>
                            </Select>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              mr={2}
                              onClick={() => ref2.current.log()}
                              // href="/product/addNewIngredients"
                            >
                              Add New Ingredient
                            </Button>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              mr={2}
                            >
                              Download
                            </Button>
                          </Box>
                        </Box>
                        <Box className="member-table">
                          <ExpandableRowTable
                            columns={memeberColumns}
                            options={options}
                            data={data}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid container mb={2}>
                            <Box className="flex-between w-100">
                              <Typography component="h4">
                                Ingredient ID - #58838
                              </Typography>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                className="retail-btn"
                                onClick={() => ref3.current.log()}
                              >
                                View More
                              </Button>
                            </Box>
                          </Grid>

                          <Box className="information-div" mt={2}>
                            <Box component="img" className="w-80" src={gm} />
                            <Box className="w-100">
                              <Box className="flex-between">
                                <Typography component="h1">
                                  Everest Garam Masala
                                </Typography>
                                <Typography
                                  component="span"
                                  className="text-danger fw-bold"
                                >
                                  Rs. 120/-
                                </Typography>
                              </Box>
                              <Box>
                                {/* <Button
                                  variant="outlined"
                                  color="error"
                                  mt={2}
                                  className="retail-btn"
                                  onClick={() => ref3.current.log()}
                                >
                                  250 GMS
                                </Button> */}
                                <Button
                                  mt={2}
                                  className="normal-text"
                                  onClick={() => ref3.current.log()}
                                >
                                  500 GMS
                                </Button>
                              </Box>

                              {/* <Typography className="font-normal" mt={1}>
                                Daal is a term originating in the Indian
                                subcontinent for dried, split pulses that do not
                                require soaking before cooking. India is the
                                largest producer of pulses in the world.
                              </Typography> */}
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ViewSuppliesModal ref={ref3} />
        <SupplyAddIngredientsModal ref={ref2} />
      </Box>
    </>
  );
}
