import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import transport1 from "../images/transport2.png";
import Revenue from "../images/revenue.png";
import cust from "../images/customer.png";
import utilization from "../images/Sales-Theatres-Wise.jpg";
import utilization1 from "../images/Revenue-Graph.jpg";
import Bredcrum from "../Bredcrum";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Calender from "../Calender/Calender";
import ordersgraph from "../images/orders-graph.jpg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import custgraph from "../images/Occupancy-Graph.jpg";
import skugraph from "../images/sku-graph.jpg";
import salesgraph from "../images/sales-summary-graph.jpg";
import genderpie from "../images/Gender-Graph.svg";
import age from "../images/Age-Graph.svg";
import cititier from "../images/City-Tier-Graph.svg";
import theatrepie from "../images/Theatre-pie.svg";
import sentigraph from "../images/Sentiment-Graph.jpg";
import heatmap from "../images/heat-map.jpg";
import occupancytheatrewise from "../images/Occupancy-Theatre-Wise.jpg";
import salesdaywise from "../images/Sales-Day-Wise.jpg";
import occupancydaywise from "../images/Occupancy-Day-Wise.jpg";
import sa1 from "../images/sa1.svg";
import sa2 from "../images/sa2.svg";
import sa3 from "../images/sa3.svg";
import performancemap from "../images/performance-map.png";
import salesdaywiserevenue from "../images/Sales-Day-Wise-revenue.svg";
import theatresalesrevenue from "../images/Sales-Theatres-Wise-revenue.svg";
import DemographicsGenderModal from "./DemographicsGenderModal";
import DemographicsAgeModal from "./DemographicsAgeModal";
import DemographicsCityTierModal from "./DemographicsCityTierModal";
import DemographicsTheatreModal from "./DemographicsTheatreModal";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const cities = ["Vizianagaram", "Visakhapatnam", "Srikakulam"];
const stores = ["All Theatres", "Groceries", "Fancy"];
const merchants = ["All Movies", "Srinivasa", "Harsha"];
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = React.useState("left");
  const [alignment1, setAlignment1] = React.useState("left");
  const [alignment2, setAlignment2] = React.useState("left");
  const [alignment3, setAlignment3] = React.useState("left");
  const [show, setShow] = React.useState(true);
  const [switchs, setSwitchs] = React.useState(true);
  const [switchs1, setSwitchs1] = React.useState(true);
  const [switchs2, setSwitchs2] = React.useState(true);
  const [switchs3, setSwitchs3] = React.useState(true);
  // const openModal = () =>{
  //     setOpen(!open);
  // }
  const ref7 = React.useRef();
  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref = useRef();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
    setShow((show) => !show);
  };
  const handleSwitch = (event, newAlignment) => {
    setAlignment(newAlignment);
    setSwitchs((switchs) => !switchs);
  };
  const handleSwitch1 = (event, newAlignment1) => {
    setAlignment1(newAlignment1);
    setSwitchs1((switchs1) => !switchs1);
  };
  const handleSwitch2 = (event, newAlignment2) => {
    setAlignment2(newAlignment2);
    setSwitchs2((switchs2) => !switchs2);
  };
  const handleSwitch3 = (event, newAlignment3) => {
    setAlignment3(newAlignment3);
    setSwitchs3((switchs3) => !switchs3);
  };
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent className="main-container">
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Calender />
                        <Button variant="outlined" className="btn-primary">
                          Today
                        </Button>
                        <Button
                          variant="outlined"
                          // className="btn-primary-outline"
                          className="retail-btn bg-white"
                        >
                          Yesterday
                        </Button>
                      </Box>

                      <Box className="filters-display">
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select d-none"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Cities</MenuItem>
                          <MenuItem>Hyderabad</MenuItem>
                          <MenuItem>Visakhapatnam</MenuItem>
                          <MenuItem>Guntur</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Cities</MenuItem>
                          <MenuItem>Hyderabad</MenuItem>
                          <MenuItem>Visakhapatnam</MenuItem>
                          <MenuItem>Guntur</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Theatres</MenuItem>
                          <MenuItem>PVR Inox</MenuItem>
                          <MenuItem>Globex</MenuItem>
                          <MenuItem>STBL Cine World</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Movies</MenuItem>
                          <MenuItem>Kushi</MenuItem>
                          <MenuItem>Jawan</MenuItem>
                          <MenuItem>NUN II</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography component="h2" mt={2} mb={2} fontWeight="bold">
                    Performance Map
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Box textAlign={"center"}>
                          <Box
                            component="img"
                            src={performancemap}
                            style={{ width: "35%" }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} className="custom-dashboard">
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <ShoppingBagIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Total Tickets Sold
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              3,500
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <CurrencyRupeeIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Total Revenue
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              7,21,357
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <PersonIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Total Occupancy
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              70%
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={3}>
                    <Card>
                      <CardContent>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <CurrencyRupeeIcon className="orders-icon" />
                          </Box>
                          <Box textAlign={"right"}>
                            <Typography
                              variant="p"
                              className="l-dashboard-title"
                            >
                              Avg Ticket Price
                            </Typography>
                            <Typography
                              variant="h3"
                              className="l-dashboard-value"
                            >
                              190
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography component="h2" mt={2} fontWeight="bold">
                    Revenue
                  </Typography>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                  mt={2}
                >
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">
                            Sales - Theatres Wise
                          </Typography>
                          <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleSwitch}
                            aria-label="text alignment"
                            style={{ float: "right" }}
                            className="custom_toggle"
                          >
                            <ToggleButton
                              value="left"
                              aria-label="left aligned"
                            >
                              Tickets
                            </ToggleButton>
                            <ToggleButton
                              value="justify"
                              aria-label="justified"
                            >
                              Revenue
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <Box>
                          {switchs ? (
                            <Box
                              component="img"
                              src={utilization}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <Box
                              component="img"
                              src={theatresalesrevenue}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">
                            Occupancy (Theatre Wise)
                          </Typography>
                        </Box>
                        <Box>
                          <Box
                            component="img"
                            src={occupancytheatrewise}
                            style={{ width: "100%" }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">
                            Sales - Day Wise
                          </Typography>
                          <ToggleButtonGroup
                            value={alignment2}
                            exclusive
                            onChange={handleSwitch2}
                            aria-label="text alignment"
                            style={{ float: "right" }}
                            className="custom_toggle"
                          >
                            <ToggleButton
                              value="left"
                              aria-label="left aligned"
                            >
                              Tickets
                            </ToggleButton>
                            <ToggleButton
                              value="justify"
                              aria-label="justified"
                            >
                              Revenue
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <Box>
                          {switchs2 ? (
                            <Box
                              component="img"
                              src={salesdaywise}
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <Box
                              component="img"
                              src={salesdaywiserevenue}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">
                            Occupancy (Day Wise)
                          </Typography>
                        </Box>
                        <Box>
                          <Box
                            component="img"
                            src={occupancydaywise}
                            style={{ width: "100%" }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography component="h2" mb={2} mt={2} fontWeight="bold">
                    Demographics
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item md={3}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography component="h6">Gender</Typography>
                            </Box>
                            <Box textAlign={"center"}>
                              <Box
                                component="img"
                                src={genderpie}
                                style={{ width: "100%" }}
                                className="pie-chart-custom-width"
                                onClick={() => ref7.current.log()}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography component="h6">Age</Typography>
                            </Box>
                            <Box textAlign={"center"}>
                              <Box
                                component="img"
                                src={age}
                                style={{ width: "100%" }}
                                className="pie-chart-custom-width"
                                onClick={() => ref1.current.log()}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography component="h6">City Tier</Typography>
                            </Box>
                            <Box textAlign={"center"}>
                              <Box
                                component="img"
                                src={cititier}
                                style={{ width: "100%" }}
                                className="pie-chart-custom-width"
                                onClick={() => ref2.current.log()}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography component="h6">Theatres</Typography>
                            </Box>
                            <Box textAlign={"center"}>
                              <Box
                                component="img"
                                src={theatrepie}
                                style={{ width: "100%" }}
                                className="pie-chart-custom-width"
                                onClick={() => ref3.current.log()}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography component="h2" mb={2} mt={2} fontWeight="bold">
                    Sentiment Analysis
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item md={4}>
                            <Box>
                              <Box
                                component="img"
                                src={sa1}
                                style={{ width: "70%" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box>
                              <Box
                                component="img"
                                src={sa2}
                                style={{ width: "70%" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={4}>
                            <Box>
                              <Box
                                component="img"
                                src={sa3}
                                style={{ width: "70%" }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <DemographicsGenderModal ref={ref7} />
        <DemographicsAgeModal ref={ref1} />
        <DemographicsCityTierModal ref={ref2} />
        <DemographicsTheatreModal ref={ref3} />
      </Box>
    </>
  );
}
