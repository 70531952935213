import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";

import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Bredcrum from "../Bredcrum";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import InspectionLogModal from "./InspectionLogModal";
import ComplianceLog from "./ComplianceLog";
import MaintainanceLog from "./MaintainanceLog";
import qr from "../images/qrcode.png";
import SeatMatrix from "./SeatMatrix";
import AddScreensConfiguratorModal from "./AddScreensConfiguratorModal";
import AddScreenConfiguratorLocationModal from "./AddScreenConfiguratorLocationModal";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SeatConfigurator() {
  const [value, setValue] = React.useState("1");
  const [location, setLocation] = React.useState("1");
  const [screen, setScreen] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setLocation(newValue);
  };
  const handleScreen = (event, newValue) => {
    setScreen(newValue);
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const ref6 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: true,
  };

  const segmentColumns = [
    { name: "Row Name", options: { filterOptions: { fullWidth: true } } },
    "Seat Start",
    "Seat End",
    "Gap Start",
    "Gap End",
    "Total Seats",
    "Class",
    "",
    "",
  ];

  const segmentData = [
    [
      "A",
      "1",
      "9",
      "4",
      "5",
      "9",
      "Recliner, Normal",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
    [
      "B",
      "1",
      "9",
      "4",
      "5",
      "9",
      "Recliner, Normal",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
    [
      "c",
      "1",
      "9",
      "4",
      "5",
      "9",
      "Recliner, Normal",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
  ];

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                              className="tabs-box seats-config-tabs1"
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab tabs cities"
                              >
                                <Tab label="Hyderabad" value="1" />
                                <Tab label="Delhi" value="2" />
                                <Tab label="Mumbai" value="3" />
                                <Tab label="Vizag" value="4" />
                              </TabList>
                            </Box>
                            <TabPanel
                              value="1"
                              className="screen-config-tabpanel1"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="screen-config-tabs2"
                                        >
                                          <Box marginBottom={1}>
                                            <SearchBar className="search-bar" />
                                          </Box>
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Tab
                                              label="Secunderabad"
                                              value="1"
                                            />
                                            <Tab label="Kukatpally" value="2" />
                                            <Tab label="Gachibowli" value="3" />
                                            <Button
                                              className="btn btn-outline-primary w-100 mt-15"
                                              onClick={() => ref6.current.log()}
                                            >
                                              Add Location
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="2"
                              className="screen-config-tabpanel1"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="screen-config-tabs2"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Box marginBottom={1}>
                                              <SearchBar className="search-bar" />
                                            </Box>
                                            <Tab
                                              label="Connaught Cir"
                                              value="1"
                                            />
                                            <Tab
                                              label="Nehru place"
                                              value="2"
                                            />
                                            <Tab label="Rivoli" value="3" />
                                            <Button
                                              className="btn btn-outline-primary w-100 mt-15"
                                              onClick={() => ref5.current.log()}
                                            >
                                              Add Location
                                            </Button>
                                            s
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="3"
                              className="screen-config-tabpanel1"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="screen-config-tabs2"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Box marginBottom={1}>
                                              <SearchBar className="search-bar" />
                                            </Box>
                                            <Tab
                                              label="Juhu Chruch Road"
                                              value="1"
                                            />
                                            <Tab label="NCPA Marg" value="2" />
                                            <Tab
                                              label="Colaba Causeway"
                                              value="3"
                                            />
                                            <Button
                                              className="btn btn-outline-primary w-100 mt-15"
                                              onClick={() => ref5.current.log()}
                                            >
                                              Add Location
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="4"
                              className="screen-config-tabpanel1"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="screen-config-tabs2"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Box marginBottom={1}>
                                              <SearchBar className="search-bar" />
                                            </Box>
                                            <Tab
                                              label="RTC Complex"
                                              value="1"
                                            />
                                            <Tab
                                              label="Maddilapalem"
                                              value="2"
                                            />
                                            <Tab
                                              label="Madhurawada"
                                              value="3"
                                            />
                                            <Button
                                              className="btn btn-outline-primary w-100 mt-15"
                                              onClick={() => ref5.current.log()}
                                            >
                                              Add Location
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="screen-config-tabpanel2"
                                        >
                                          <TabContext value={screen}>
                                            <Box
                                              sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                              }}
                                              className="screen-config-tabs3"
                                            >
                                              <TabList
                                                onChange={handleScreen}
                                                aria-label="lab tabs cities"
                                              >
                                                <Tab
                                                  label="Screen 1"
                                                  value="1"
                                                />
                                                <Tab
                                                  label="Screen 2"
                                                  value="2"
                                                />
                                                <Tab
                                                  label="Screen 3"
                                                  value="3"
                                                />
                                                <Tab
                                                  label="Screen 4"
                                                  value="4"
                                                />
                                                <Button
                                                  onClick={() =>
                                                    ref5.current.log()
                                                  }
                                                >
                                                  Add Screen
                                                </Button>
                                              </TabList>
                                            </Box>
                                            <TabPanel
                                              value="1"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="2"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="3"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                            <TabPanel
                                              value="4"
                                              className="screen-config-tabpanel3"
                                            >
                                              <Grid container spacing={2}>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <MemberTable
                                                            columns={
                                                              segmentColumns
                                                            }
                                                            options={options}
                                                            data={segmentData}
                                                          />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    mt={1}
                                                  >
                                                    <Grid item md={3}>
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        SEAT QR CODE
                                                      </Typography>
                                                      <Box
                                                        component="img"
                                                        src={qr}
                                                      ></Box>
                                                    </Grid>
                                                    <Grid item md={9}>
                                                      <Card>
                                                        <CardContent>
                                                          <Typography
                                                            className="font-bold"
                                                            component="h4"
                                                            mb={1}
                                                          >
                                                            SEAT DETAILS
                                                          </Typography>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Number
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat Type
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Recliner
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="4"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Seat ID
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  A 1_R
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                          <Grid container>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Class
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  Classic
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              md="6"
                                                              className="table-border"
                                                            >
                                                              <Box
                                                                md="4"
                                                                textAlign={
                                                                  "center"
                                                                }
                                                                padding={1}
                                                              >
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-details-title"
                                                                >
                                                                  Dimension (CM)
                                                                </Typography>
                                                                <Typography
                                                                  component="p"
                                                                  className="transport-detail-value"
                                                                >
                                                                  76*79*77
                                                                </Typography>
                                                              </Box>
                                                            </Grid>
                                                          </Grid>
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item md={6}>
                                                  <Card>
                                                    <CardContent>
                                                      <Grid
                                                        container
                                                        spacing={2}
                                                      >
                                                        <Grid item md={12}>
                                                          <SeatMatrix />
                                                        </Grid>
                                                      </Grid>
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              </Grid>
                                            </TabPanel>
                                          </TabContext>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
        <InspectionLogModal ref={ref1} />
        <ComplianceLog ref={ref2} />
        <MaintainanceLog ref={ref3} />
        <AddScreensConfiguratorModal ref={ref5} />
        <AddScreenConfiguratorLocationModal ref={ref6} />
      </Box>
    </>
  );
}
