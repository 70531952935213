import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import upload from "../images/upload.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddSegmentModal = forwardRef((props, ref1) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref1, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content subsegment-product adm-modal"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              SEGMENT DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>

          <Divider></Divider>

          <Grid container className="p-15">
            <Grid item xs={2} sm={12} md={12}>
              <FormControl>
                <Box src={upload} component="img" className="p-img" mb={2} />
                <OutlinedInput value="" type="file" className="file-upload" />
              </FormControl>
              <Box mt={2}>
                <FormControl className="w-100">
                  <Typography component="label">Segment Name</Typography>
                  <OutlinedInput value="" />
                </FormControl>
              </Box>
              {/* <Box mt={2}>
                                  <FormControl className='w-100'>
                                      <Typography component="label">Isle</Typography>
                                      <OutlinedInput value='' />
                                  </FormControl>
  
                              </Box> */}
              {/* <Box mt={2}>
                                  <FormControl className='w-100'>
                                      <Typography component="label">Rows</Typography>
                                      <OutlinedInput value='' />
                                  </FormControl>
  
                              </Box> */}
            </Grid>
          </Grid>
          <Divider></Divider>

          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default AddSegmentModal;
