import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import MemberTable from "../Customer/MemberTable";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Switch,
  Divider,
} from "@mui/material";
import test from "../images/test.jpg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const segmentColumns = [
  { name: "DATE", options: { filterOptions: { fullWidth: true } } },
  "CLEANING",
  "STATUS",
  "COMMENTS",
  {
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Button
            variant="outlined"
            color="error"
            mt={2}
            className="retail-btn"
            // onClick={() => ref6.current.log()}
          >
            Edit
          </Button>
        );
      },
    },
  },
];

const options = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  onFilterChange: (changedColumn, filterList) => {
    console.log(changedColumn, filterList);
  },
  selectableRows: false,
  isRowSelectable: false,
  expandableRowsHeader: false,
  icons: { Add: () => "Add Row" },
  filterType: "dropdown",
  responsive: "scrollMaxHeight",
  rowsPerPage: 10,
  expandableRows: false,
};

const segmentData = [
  ["01/08/2023", "Washroom", "Done", "NA"],
  ["02/08/2023", "Lobby", "Done", "NA"],
  ["03/08/2023", "Seats", "Pending", "Due Date : 10/08/2023"],
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const CleaningLog = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal"
      >
        <Box sx={{ ...style }} className="profile-modal">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                Cleaning Log
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2} className="p-15">
            <Grid item md={12}>
              <MemberTable
                columns={segmentColumns}
                options={options}
                data={segmentData}
              />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default CleaningLog;
