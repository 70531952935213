import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";

import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Bredcrum from "../Bredcrum";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import InspectionLogModal from "./InspectionLogModal";
import ComplianceLog from "./ComplianceLog";
import MaintainanceLog from "./MaintainanceLog";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function ScreenConfigurator() {
  const [value, setValue] = React.useState("1");
  const [location, setLocation] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setLocation(newValue);
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: true,
  };

  const screenutilcolumns = [
    { name: "Screen Name", options: { filterOptions: { fullWidth: true } } },
    "Seat Capacity",
    "Sound",
    "Status",
    "",
  ];

  const screenutildata = [
    [
      "Screen 1",
      "90",
      "Dolby Atmos",
      <IOSSwitch />,
      <Button className="btn btn-outline-primary">Edit</Button>,
    ],
  ];

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                              className="tabs-box"
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab tabs cities"
                              >
                                <Tab label="Hyderabad" value="1" />
                                <Tab label="Delhi" value="2" />
                                <Tab label="Mumbai" value="3" />
                                <Tab label="Vizag" value="4" />
                              </TabList>
                            </Box>
                            <TabPanel
                              value="1"
                              className="tab-panel-description"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="location-sub-tabs"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Tab
                                              label="Secunderabad"
                                              value="1"
                                            />
                                            <Tab label="Kukatpally" value="2" />
                                            <Tab label="Gachibowli" value="3" />
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="2"
                              className="tab-panel-description"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="location-sub-tabs"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Tab
                                              label="Cannaught Circle"
                                              value="1"
                                            />
                                            <Tab
                                              label="Nehru Palace"
                                              value="2"
                                            />
                                            <Tab label="Rivoli" value="3" />
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="3"
                              className="tab-panel-description"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="location-sub-tabs"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Tab
                                              label="Juhu Chruch Rd"
                                              value="1"
                                            />
                                            <Tab label="NCPA Marg" value="2" />
                                            <Tab
                                              label="Colaba Causeway"
                                              value="3"
                                            />
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="4"
                              className="tab-panel-description"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={12}>
                                  <TabContext value={location}>
                                    <Grid container spacing={2}>
                                      <Grid item md={2}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="location-sub-tabs"
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs locations"
                                          >
                                            <Tab
                                              label="RTC Complex"
                                              value="1"
                                            />
                                            <Tab
                                              label="Maddilapalem"
                                              value="2"
                                            />
                                            <Tab label="Yendada" value="3" />
                                          </TabList>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        md={10}
                                        className="color-attach"
                                      >
                                        <TabPanel
                                          value="1"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="tab-panel-sub-description"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        LOCATION DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Location Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Secunderabad
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Address
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Cyber Towers, 1st
                                                          Road, Banjara Hills
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Type
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Multi Screen
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={2}>
                                                    <Box
                                                      className="flex-between w-100"
                                                      mt={2}
                                                      paddingLeft={2}
                                                    >
                                                      <Typography
                                                        className="font-bold"
                                                        component="h4"
                                                      >
                                                        MANAGER DETAILS
                                                      </Typography>
                                                      <Button
                                                        variant="outlined"
                                                        mt={2}
                                                        mb={2}
                                                        className="retail-btn"
                                                        mr={2}
                                                        //   onClick={() =>
                                                        //     ref8.current.log()
                                                        //   }
                                                      >
                                                        Edit
                                                      </Button>
                                                    </Box>
                                                  </Grid>
                                                  <Grid container mt={2} mb={2}>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Manager Name
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Rama Swamy
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Phone Number
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          9030369836
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="4"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Email
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          ramaswamy@pvr.in
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      AMENITIES LIST
                                                    </Typography>
                                                  </Box>
                                                  <Grid container mt={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Valet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Occupancy
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          500
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Screens
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          5
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Self Parking
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container mb={2}>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Elevator
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Food Court
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          3D
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      md="3"
                                                      className="table-border"
                                                    >
                                                      <Box
                                                        md="4"
                                                        textAlign={"center"}
                                                        padding={1}
                                                      >
                                                        <Typography
                                                          component="p"
                                                          className="transport-details-title"
                                                        >
                                                          Toilet
                                                        </Typography>
                                                        <Typography
                                                          component="p"
                                                          className="transport-detail-value"
                                                        >
                                                          Yes
                                                        </Typography>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      SCREEN DETAILS
                                                    </Typography>
                                                  </Box>
                                                  <Grid container>
                                                    <Grid item md={12}>
                                                      <MemberTable
                                                        columns={
                                                          screenutilcolumns
                                                        }
                                                        options={options}
                                                        data={screenutildata}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      COMPLIANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref2.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      INSPECTION LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref1.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                  <Divider></Divider>
                                                  <Box
                                                    className="flex-between w-100"
                                                    mt={2}
                                                  >
                                                    <Typography
                                                      className="font-bold"
                                                      component="h4"
                                                    >
                                                      MAINTAINANCE LOG
                                                    </Typography>
                                                    <Button
                                                      className="btn btn-outline-primary"
                                                      onClick={() =>
                                                        ref3.current.log()
                                                      }
                                                    >
                                                      View
                                                    </Button>
                                                  </Box>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                      </Grid>
                                    </Grid>
                                  </TabContext>
                                </Grid>
                              </Grid>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
        <InspectionLogModal ref={ref1} />
        <ComplianceLog ref={ref2} />
        <MaintainanceLog ref={ref3} />
      </Box>
    </>
  );
}
