import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";

import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Bredcrum from "../Bredcrum";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import InspectionLogModal from "./InspectionLogModal";
import EquipmentInspectionLog from "./EquipmentInspectionLog";
import CleaningLog from "./CleaningLog";
import AddScreensConfiguratorModal from "./AddScreensConfiguratorModal";
import Tabs from "@mui/material/Tabs";
import AddScreenConfiguratorLocationModal from "./AddScreenConfiguratorLocationModal";
import MaintainanceLog from "./MaintainanceLog";
import ComplianceLog from "./ComplianceLog";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };
// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

export default function ScreenConfigurator() {
  const [value, setValue] = React.useState("1");
  const [screensvalue, setScreensValue] = React.useState("1");
  const [location, setLocation] = React.useState("1");
  const [dlocation, setdLocation] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const screensChange = (event, newValue) => {
    setScreensValue(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setLocation(newValue);
  };
  const handleChange2 = (event, newValue) => {
    setdLocation(newValue);
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const ref8 = React.useRef();
  const ref6 = React.useRef();
  const ref7 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const summarycolumns = [
    { name: "Screen Name", options: { filterOptions: { fullWidth: true } } },
    "Seat Capacity",
    "Sound",
    "Status",
    "",
  ];

  const summarydata = [
    [
      "Screen 1",
      "90",
      "Dolby Atmos",
      <IOSSwitch />,
      <Button className="btn btn-outline-primary">Edit</Button>,
    ],
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: true,
  };

  const screenutilcolumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Movie Name",
    "Show Time",
    "Occupancy %",
    "",
    "",
  ];

  const screenutildata = [
    [
      "2/08/2023",
      "MEG 2",
      "11:00 AM",
      "70%",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
    [
      "5/08/2023",
      "Gaddar 2",
      "03:00 PM",
      "50%",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
  ];

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                              className="tabs-box"
                              display={"flex"}
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="Hyderabad" value="1" />
                                <Tab label="Delhi" value="2" />
                                <Tab label="Mumbai" value="3" />
                                <Tab label="Vizag" value="4" />
                              </TabList>
                              <Button className="btn-add-city">Add City</Button>
                            </Box>
                            <TabPanel
                              value="1"
                              className="tab-panel-description"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={2}>
                                  <Box marginBottom={1}>
                                    <SearchBar className="search-bar" />
                                  </Box>
                                  <TabContext value={location}>
                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <TabList
                                        orientation="vertical"
                                        onChange={handleChange1}
                                        aria-label="Vertical tabs example"
                                        className="vertical-tabs-selection"
                                      >
                                        <Tab label="Secunderabad" value="1" />
                                        <Tab label="Kukatpally" value="2" />
                                        <Tab label="Gachibowli" value="3" />
                                        <Tab label="Jublee Hills" value="4" />
                                        <Button
                                          onClick={() => ref8.current.log()}
                                        >
                                          Add Screen
                                        </Button>
                                      </TabList>
                                    </Box>
                                  </TabContext>
                                  {/* <Accordion defaultExpanded={true}>
                                    <AccordionSummary
                                      expandIcon={
                                        <svg
                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                          focusable="false"
                                          aria-hidden="true"
                                          viewBox="0 0 24 24"
                                          data-testid="ArrowDropUpIcon"
                                        >
                                          <path d="m7 16 4-7 4 7z"></path>
                                        </svg>
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className="accordian-header"
                                    >
                                      Secunderabad
                                    </AccordionSummary>
                                    <AccordionDetails className="vertical-tabs-box">
                                      <TabContext value={location}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange1}
                                            aria-label="Vertical tabs example"
                                            className="vertical-tabs-selection"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                            <Button
                                              onClick={() => ref8.current.log()}
                                            >
                                              Add Screen
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </TabContext>
                                    </AccordionDetails>
                                  </Accordion> */}
                                  {/* <Accordion>
                                    <AccordionSummary
                                      expandIcon={
                                        <svg
                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                          focusable="false"
                                          aria-hidden="true"
                                          viewBox="0 0 24 24"
                                          data-testid="ArrowDropUpIcon"
                                        >
                                          <path d="m7 16 4-7 4 7z"></path>
                                        </svg>
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className="accordian-header"
                                    >
                                      Kukatpally
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box
                                        sx={{
                                          borderBottom: 1,
                                          borderColor: "divider",
                                        }}
                                      >
                                        <TabList
                                          orientation="vertical"
                                          onChange={handleChange}
                                          aria-label="Vertical tabs example"
                                        >
                                          <Tab label="Audi 1" value="1" />
                                          <Tab label="Audi 2" value="2" />
                                          <Tab label="Audi 3" value="3" />
                                          <Tab label="Audi 4" value="4" />
                                        </TabList>
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion> */}
                                  {/* <Accordion>
                                    <AccordionSummary
                                      expandIcon={
                                        <svg
                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                          focusable="false"
                                          aria-hidden="true"
                                          viewBox="0 0 24 24"
                                          data-testid="ArrowDropUpIcon"
                                        >
                                          <path d="m7 16 4-7 4 7z"></path>
                                        </svg>
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className="accordian-header"
                                    >
                                      Gachibowli
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box
                                        sx={{
                                          borderBottom: 1,
                                          borderColor: "divider",
                                        }}
                                      >
                                        <TabList
                                          orientation="vertical"
                                          onChange={handleChange}
                                          aria-label="Vertical tabs example"
                                        >
                                          <Tab label="Audi 1" value="1" />
                                          <Tab label="Audi 2" value="2" />
                                          <Tab label="Audi 3" value="3" />
                                          <Tab label="Audi 4" value="4" />
                                        </TabList>
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion> */}
                                  {/* <Accordion>
                                    <AccordionSummary
                                      expandIcon={
                                        <svg
                                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                          focusable="false"
                                          aria-hidden="true"
                                          viewBox="0 0 24 24"
                                          data-testid="ArrowDropUpIcon"
                                        >
                                          <path d="m7 16 4-7 4 7z"></path>
                                        </svg>
                                      }
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className="accordian-header"
                                    >
                                      Jubilee Hills
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Box
                                        sx={{
                                          borderBottom: 1,
                                          borderColor: "divider",
                                        }}
                                      >
                                        <TabList
                                          orientation="vertical"
                                          onChange={handleChange}
                                          aria-label="Vertical tabs example"
                                        >
                                          <Tab label="Audi 1" value="1" />
                                          <Tab label="Audi 2" value="2" />
                                          <Tab label="Audi 3" value="3" />
                                          <Tab label="Audi 4" value="4" />
                                        </TabList>
                                      </Box>
                                    </AccordionDetails>
                                  </Accordion> */}

                                  <Button
                                    className="btn btn-outline-primary w-100 mt-15"
                                    onClick={() => ref5.current.log()}
                                  >
                                    Add Location
                                  </Button>
                                </Grid>
                                <Grid item md={10} className="color-attach">
                                  <TabPanel
                                    value="1"
                                    className="tab-panel-sub-description"
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item md={12}>
                                        <Card>
                                          <CardContent>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                LOCATION DETAILS
                                              </Typography>
                                              <Button
                                                variant="outlined"
                                                mt={2}
                                                mb={2}
                                                className="retail-btn"
                                                mr={2}
                                                // onClick={() =>
                                                //   ref8.current.log()
                                                // }
                                              >
                                                Edit
                                              </Button>
                                            </Box>
                                            <Grid container mt={2} mb={2}>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Location Name
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Secunderabad
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Address
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Cyber Heights, 1st, Rd No.
                                                    2, Banjara Hills
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Type
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Multi Screen
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                MANAGER DETAILS
                                              </Typography>
                                              <Button
                                                variant="outlined"
                                                mt={2}
                                                mb={2}
                                                className="retail-btn"
                                                mr={2}
                                                // onClick={() =>
                                                //   ref8.current.log()
                                                // }
                                              >
                                                Edit
                                              </Button>
                                            </Box>
                                            <Grid container mt={2} mb={2}>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Manager Name
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Ramesh Rao
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Phone Number
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    9876543210
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Email
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    rameshrao.34@gmail.com
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Grid container spacing={2} mt={1}>
                                              <Box
                                                className="flex-between w-100"
                                                paddingLeft={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  AMENITIES LIST
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  onClick={() =>
                                                    ref8.current.log()
                                                  }
                                                >
                                                  Add
                                                </Button>
                                              </Box>
                                            </Grid>
                                            <Grid container mt={2}>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Valet
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Yes
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Occupancy
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    500
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Screens
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    5
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Self Parking
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Yes
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>

                                            <Grid container mb={2}>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Elevator
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Yes
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Food Court
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Yes
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    3D
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Yes
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="3"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Toilets
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Yes
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Grid container spacing={2} mt={2}>
                                              <Box
                                                className="flex-between w-100"
                                                paddingLeft={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SCREEN SUMMARY
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  onClick={() =>
                                                    ref8.current.log()
                                                  }
                                                >
                                                  Add
                                                </Button>
                                              </Box>
                                              <Grid item md={12}>
                                                <MemberTable
                                                  columns={summarycolumns}
                                                  options={options}
                                                  data={summarydata}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                                paddingLeft={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SCREEN DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  onClick={() =>
                                                    ref8.current.log()
                                                  }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                            </Grid>
                                            <TabContext value={screensvalue}>
                                              <Grid container spacing={2}>
                                                <Grid item md={12}>
                                                  <TabList
                                                    onChange={screensChange}
                                                    aria-label="basic tabs example"
                                                    className="custom-tab"
                                                  >
                                                    {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                                                    <Tab
                                                      label="Audi 1"
                                                      value="1"
                                                    />
                                                    <Tab
                                                      label="Audi 2"
                                                      value="2"
                                                    />
                                                    <Tab
                                                      label="Audi 3"
                                                      value="3"
                                                    />
                                                  </TabList>
                                                </Grid>
                                                <Grid item md={12}>
                                                  <TabPanel value="1">
                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Name
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            Audi 1
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Format
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            3D
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Dimension
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            70 ft
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Info
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            HD
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Rows
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            9
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Seat Start
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            1
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Seat End
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            120
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Total Seats
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            120
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </TabPanel>
                                                  <TabPanel value="2">
                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Name
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            Audi 2
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Format
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            3D
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Dimension
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            70 ft
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Info
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            HD
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Rows
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            9
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Seat Start
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            1
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Seat End
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            120
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Total Seats
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            120
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </TabPanel>
                                                  <TabPanel value="3">
                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Name
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            Audi 3
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Format
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            3D
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Dimension
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            70 ft
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Screen Info
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            HD
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Rows
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            9
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Seat Start
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            1
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Seat End
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            120
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        md="3"
                                                        className="table-border"
                                                      >
                                                        <Box
                                                          md="4"
                                                          textAlign={"center"}
                                                          padding={1}
                                                        >
                                                          <Typography
                                                            component="p"
                                                            className="transport-details-title"
                                                          >
                                                            Total Seats
                                                          </Typography>
                                                          <Typography
                                                            component="p"
                                                            className="transport-detail-value"
                                                          >
                                                            120
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </TabPanel>
                                                </Grid>
                                              </Grid>
                                            </TabContext>

                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                SYSTEM DETAILS
                                              </Typography>
                                            </Box>
                                            <Grid container mt={2}>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    Sound Info
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Dolby Atmos
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    System Name
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    JBL
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                md="4"
                                                className="table-border"
                                              >
                                                <Box
                                                  md="4"
                                                  textAlign={"center"}
                                                  padding={1}
                                                >
                                                  <Typography
                                                    component="p"
                                                    className="transport-details-title"
                                                  >
                                                    System Specs
                                                  </Typography>
                                                  <Typography
                                                    component="p"
                                                    className="transport-detail-value"
                                                  >
                                                    Crystal Clear Sound
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                SCREEN UTILIZATION
                                              </Typography>
                                            </Box>
                                            <Grid container>
                                              <Grid item md={12}>
                                                <MemberTable
                                                  columns={screenutilcolumns}
                                                  options={options}
                                                  data={screenutildata}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                INSPECTION LOG
                                              </Typography>
                                              <Button
                                                className="btn btn-outline-primary"
                                                onClick={() =>
                                                  ref1.current.log()
                                                }
                                              >
                                                View
                                              </Button>
                                            </Box>
                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                EQUIPMENT INSPECTION LOG
                                              </Typography>
                                              <Button
                                                className="btn btn-outline-primary"
                                                onClick={() =>
                                                  ref2.current.log()
                                                }
                                              >
                                                View
                                              </Button>
                                            </Box>
                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                CLEANING LOG
                                              </Typography>
                                              <Button
                                                className="btn btn-outline-primary"
                                                onClick={() =>
                                                  ref3.current.log()
                                                }
                                              >
                                                View
                                              </Button>
                                            </Box>
                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                MAINTENANCE LOG
                                              </Typography>
                                              <Button
                                                className="btn btn-outline-primary"
                                                onClick={() =>
                                                  ref6.current.log()
                                                }
                                              >
                                                View
                                              </Button>
                                            </Box>
                                            <Divider></Divider>
                                            <Box
                                              className="flex-between w-100"
                                              mt={2}
                                            >
                                              <Typography
                                                className="font-bold"
                                                component="h4"
                                              >
                                                COMPLAINCES LOG
                                              </Typography>
                                              <Button
                                                className="btn btn-outline-primary"
                                                onClick={() =>
                                                  ref7.current.log()
                                                }
                                              >
                                                View
                                              </Button>
                                            </Box>
                                          </CardContent>
                                        </Card>
                                      </Grid>
                                    </Grid>
                                  </TabPanel>
                                  <TabPanel
                                    value="2"
                                    className="tab-panel-sub-description"
                                  ></TabPanel>
                                  <TabPanel
                                    value="3"
                                    className="tab-panel-sub-description"
                                  ></TabPanel>
                                  <TabPanel
                                    value="4"
                                    className="tab-panel-sub-description"
                                  ></TabPanel>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel
                              value="2"
                              className="tab-panel-description"
                            >
                              <TabContext value={dlocation}>
                                <Grid container spacing={2}>
                                  <Grid item md={2}>
                                    <Box marginBottom={1}>
                                      <SearchBar className="search-bar" />
                                    </Box>
                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <TabList
                                        orientation="vertical"
                                        onChange={handleChange2}
                                        aria-label="Vertical tabs example"
                                        className="vertical-tabs-selection"
                                      >
                                        <Tab label="New Delhi" value="1" />
                                        <Tab label="Mehrauli" value="2" />
                                        <Tab label="Dwarka" value="3" />
                                        <Tab
                                          label="Delhi Cantonment"
                                          value="4"
                                        />
                                        <Button
                                          onClick={() => ref8.current.log()}
                                        >
                                          Add Screen
                                        </Button>
                                      </TabList>
                                    </Box>
                                    {/* <Accordion defaultExpanded={true}>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        New Delhi
                                      </AccordionSummary>
                                      <AccordionDetails className="vertical-tabs-box">
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange2}
                                            aria-label="Vertical tabs example"
                                            className="vertical-tabs-selection"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                            <Button
                                              onClick={() => ref8.current.log()}
                                            >
                                              Add Screen
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Mehrauli
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Dwarka
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Delhi Cantonmen
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion> */}
                                    <Button
                                      className="btn btn-outline-primary w-100 mt-15"
                                      onClick={() => ref5.current.log()}
                                    >
                                      Add Location
                                    </Button>
                                  </Grid>
                                  <Grid item md={10} className="color-attach">
                                    <TabPanel
                                      value="1"
                                      className="tab-panel-sub-description"
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item md={12}>
                                          <Card>
                                            <CardContent>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  LOCATION DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  // onClick={() =>
                                                  //   ref8.current.log()
                                                  // }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                              <Grid container mt={2} mb={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Location Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Delhi
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Address
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Cyber Heights, 1st, Rd No.
                                                      2, Delhi Contenmont
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Type
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Multi Screen
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  MANAGER DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  // onClick={() =>
                                                  //   ref8.current.log()
                                                  // }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                              <Grid container mt={2} mb={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Manager Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Ramesh Rao
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Phone Number
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      9876543210
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Email
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      rameshrao.34@gmail.com
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Grid
                                                container
                                                spacing={2}
                                                mt={1}
                                              >
                                                <Box
                                                  className="flex-between w-100"
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    AMENITIES LIST
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </Box>
                                              </Grid>
                                              <Grid container mt={2}>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Valet
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Occupancy
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      500
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Screens
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      5
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Self Parking
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>

                                              <Grid container mb={2}>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Elevator
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Food Court
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      3D
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Toilets
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Grid
                                                container
                                                spacing={2}
                                                mt={2}
                                              >
                                                <Box
                                                  className="flex-between w-100"
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    SCREEN SUMMARY
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </Box>
                                                <Grid item md={12}>
                                                  <MemberTable
                                                    columns={summarycolumns}
                                                    options={options}
                                                    data={summarydata}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Box
                                                  className="flex-between w-100"
                                                  mt={2}
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    SCREEN DETAILS
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Edit
                                                  </Button>
                                                </Box>
                                              </Grid>
                                              <TabContext value={screensvalue}>
                                                <Grid container spacing={2}>
                                                  <Grid item md={12}>
                                                    <TabList
                                                      onChange={screensChange}
                                                      aria-label="basic tabs example"
                                                      className="custom-tab"
                                                    >
                                                      {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                                                      <Tab
                                                        label="Audi 1"
                                                        value="1"
                                                      />
                                                      <Tab
                                                        label="Audi 2"
                                                        value="2"
                                                      />
                                                      <Tab
                                                        label="Audi 3"
                                                        value="3"
                                                      />
                                                    </TabList>
                                                  </Grid>
                                                  <Grid item md={12}>
                                                    <TabPanel value="1">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 2
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 3
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                  </Grid>
                                                </Grid>
                                              </TabContext>

                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SYSTEM DETAILS
                                                </Typography>
                                              </Box>
                                              <Grid container mt={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Sound Info
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Dolby Atmos
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      System Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      JBL
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      System Specs
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Crystal Clear Sound
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SCREEN UTILIZATION
                                                </Typography>
                                              </Box>
                                              <Grid container>
                                                <Grid item md={12}>
                                                  <MemberTable
                                                    columns={screenutilcolumns}
                                                    options={options}
                                                    data={screenutildata}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  INSPECTION LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref1.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  EQUIPMENT INSPECTION LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref2.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  CLEANING LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref3.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  MAINTENANCE LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref6.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  COMPLAINCES LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref7.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </TabPanel>
                                    <TabPanel
                                      value="2"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="3"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="4"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                  </Grid>
                                </Grid>
                              </TabContext>
                            </TabPanel>

                            <TabPanel
                              value="3"
                              className="tab-panel-description"
                            >
                              <TabContext value={dlocation}>
                                <Grid container spacing={2}>
                                  <Grid item md={2}>
                                    <Box marginBottom={1}>
                                      <SearchBar className="search-bar" />
                                    </Box>

                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <TabList
                                        orientation="vertical"
                                        onChange={handleChange2}
                                        aria-label="Vertical tabs example"
                                        className="vertical-tabs-selection"
                                      >
                                        <Tab label="Marine Drive" value="1" />
                                        <Tab
                                          label="Chowpatti Beach"
                                          value="2"
                                        />
                                        <Tab label="Andheri" value="3" />
                                        <Tab label="Borivalli East" value="4" />
                                        <Button
                                          onClick={() => ref8.current.log()}
                                        >
                                          Add Screen
                                        </Button>
                                      </TabList>
                                    </Box>
                                    {/* <Accordion defaultExpanded={true}>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Marine Drive
                                      </AccordionSummary>
                                      <AccordionDetails className="vertical-tabs-box">
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange2}
                                            aria-label="Vertical tabs example"
                                            className="vertical-tabs-selection"
                                          >
                                            <Tab label="Marine Drive" value="1" />
                                            <Tab label="Chowpatti Beach" value="2" />
                                            <Tab label="Andheri" value="3" />
                                            <Tab label="Borivalli East" value="4" />
                                            <Button
                                              onClick={() => ref8.current.log()}
                                            >
                                              Add Screen
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Chowpatti Beach
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Andheri
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Borivali East
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion> */}
                                    <Button
                                      className="btn btn-outline-primary w-100 mt-15"
                                      onClick={() => ref5.current.log()}
                                    >
                                      Add Location
                                    </Button>
                                  </Grid>
                                  <Grid item md={10} className="color-attach">
                                    <TabPanel
                                      value="1"
                                      className="tab-panel-sub-description"
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item md={12}>
                                          <Card>
                                            <CardContent>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  LOCATION DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  // onClick={() =>
                                                  //   ref8.current.log()
                                                  // }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                              <Grid container mt={2} mb={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Location Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Mumbai
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Address
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Cyber Heights, 1st, Rd No.
                                                      2, Mumbai
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Type
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Multi Screen
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  MANAGER DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  // onClick={() =>
                                                  //   ref8.current.log()
                                                  // }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                              <Grid container mt={2} mb={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Manager Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Ramesh Rao
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Phone Number
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      9876543210
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Email
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      rameshrao.34@gmail.com
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Grid
                                                container
                                                spacing={2}
                                                mt={1}
                                              >
                                                <Box
                                                  className="flex-between w-100"
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    AMENITIES LIST
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </Box>
                                              </Grid>
                                              <Grid container mt={2}>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Valet
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Occupancy
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      500
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Screens
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      5
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Self Parking
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>

                                              <Grid container mb={2}>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Elevator
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Food Court
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      3D
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Toilets
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Grid
                                                container
                                                spacing={2}
                                                mt={2}
                                              >
                                                <Box
                                                  className="flex-between w-100"
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    SCREEN SUMMARY
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </Box>
                                                <Grid item md={12}>
                                                  <MemberTable
                                                    columns={summarycolumns}
                                                    options={options}
                                                    data={summarydata}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Box
                                                  className="flex-between w-100"
                                                  mt={2}
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    SCREEN DETAILS
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Edit
                                                  </Button>
                                                </Box>
                                              </Grid>
                                              <TabContext value={screensvalue}>
                                                <Grid container spacing={2}>
                                                  <Grid item md={12}>
                                                    <TabList
                                                      onChange={screensChange}
                                                      aria-label="basic tabs example"
                                                      className="custom-tab"
                                                    >
                                                      {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                                                      <Tab
                                                        label="Audi 1"
                                                        value="1"
                                                      />
                                                      <Tab
                                                        label="Audi 2"
                                                        value="2"
                                                      />
                                                      <Tab
                                                        label="Audi 3"
                                                        value="3"
                                                      />
                                                    </TabList>
                                                  </Grid>
                                                  <Grid item md={12}>
                                                    <TabPanel value="1">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 2
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 3
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                  </Grid>
                                                </Grid>
                                              </TabContext>

                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SYSTEM DETAILS
                                                </Typography>
                                              </Box>
                                              <Grid container mt={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Sound Info
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Dolby Atmos
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      System Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      JBL
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      System Specs
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Crystal Clear Sound
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SCREEN UTILIZATION
                                                </Typography>
                                              </Box>
                                              <Grid container>
                                                <Grid item md={12}>
                                                  <MemberTable
                                                    columns={screenutilcolumns}
                                                    options={options}
                                                    data={screenutildata}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  INSPECTION LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref1.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  EQUIPMENT INSPECTION LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref2.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  CLEANING LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref3.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  MAINTENANCE LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref6.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  COMPLAINCES LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref7.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </TabPanel>
                                    <TabPanel
                                      value="2"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="3"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="4"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                  </Grid>
                                </Grid>
                              </TabContext>
                            </TabPanel>
                            <TabPanel
                              value="4"
                              className="tab-panel-description"
                            >
                              <TabContext value={dlocation}>
                                <Grid container spacing={2}>
                                  <Grid item md={2}>
                                    <Box marginBottom={1}>
                                      <SearchBar className="search-bar" />
                                    </Box>

                                    <Box
                                      sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                      }}
                                    >
                                      <TabList
                                        orientation="vertical"
                                        onChange={handleChange2}
                                        aria-label="Vertical tabs example"
                                        className="vertical-tabs-selection"
                                      >
                                        <Tab label="RTC Complex" value="1" />
                                        <Tab label="Maddilapalem" value="2" />
                                        <Tab label="Madhurawada" value="3" />
                                        <Tab label="Yendada" value="4" />
                                        <Button
                                          onClick={() => ref8.current.log()}
                                        >
                                          Add Screen
                                        </Button>
                                      </TabList>
                                    </Box>
                                    {/* <Accordion defaultExpanded={true}>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        RTC Complex
                                      </AccordionSummary>
                                      <AccordionDetails className="vertical-tabs-box">
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange2}
                                            aria-label="Vertical tabs example"
                                            className="vertical-tabs-selection"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                            <Button
                                              onClick={() => ref8.current.log()}
                                            >
                                              Add Screen
                                            </Button>
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Maddilapalem
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Madhurawada
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            className="remove-indicator"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Yendada
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion> */}
                                    <Button
                                      className="btn btn-outline-primary w-100 mt-15"
                                      onClick={() => ref5.current.log()}
                                    >
                                      Add Location
                                    </Button>
                                  </Grid>
                                  <Grid item md={10} className="color-attach">
                                    <TabPanel
                                      value="1"
                                      className="tab-panel-sub-description"
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item md={12}>
                                          <Card>
                                            <CardContent>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  LOCATION DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  // onClick={() =>
                                                  //   ref8.current.log()
                                                  // }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                              <Grid container mt={2} mb={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Location Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      RTC Complex
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Address
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Sangam Sarat, Near Vaibhav
                                                      Jewellery
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Type
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Multi Screen
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  MANAGER DETAILS
                                                </Typography>
                                                <Button
                                                  variant="outlined"
                                                  mt={2}
                                                  mb={2}
                                                  className="retail-btn"
                                                  mr={2}
                                                  // onClick={() =>
                                                  //   ref8.current.log()
                                                  // }
                                                >
                                                  Edit
                                                </Button>
                                              </Box>
                                              <Grid container mt={2} mb={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Manager Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Ramesh Rao
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Phone Number
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      9876543210
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Email
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      rameshrao.34@gmail.com
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Grid
                                                container
                                                spacing={2}
                                                mt={1}
                                              >
                                                <Box
                                                  className="flex-between w-100"
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    AMENITIES LIST
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </Box>
                                              </Grid>
                                              <Grid container mt={2}>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Valet
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Occupancy
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      500
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Screens
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      5
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Self Parking
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>

                                              <Grid container mb={2}>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Elevator
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Food Court
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      3D
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="3"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Toilets
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Yes
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Grid
                                                container
                                                spacing={2}
                                                mt={2}
                                              >
                                                <Box
                                                  className="flex-between w-100"
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    SCREEN SUMMARY
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </Box>
                                                <Grid item md={12}>
                                                  <MemberTable
                                                    columns={summarycolumns}
                                                    options={options}
                                                    data={summarydata}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Grid container spacing={2}>
                                                <Box
                                                  className="flex-between w-100"
                                                  mt={2}
                                                  paddingLeft={2}
                                                >
                                                  <Typography
                                                    className="font-bold"
                                                    component="h4"
                                                  >
                                                    SCREEN DETAILS
                                                  </Typography>
                                                  <Button
                                                    variant="outlined"
                                                    mt={2}
                                                    mb={2}
                                                    className="retail-btn"
                                                    mr={2}
                                                    onClick={() =>
                                                      ref8.current.log()
                                                    }
                                                  >
                                                    Edit
                                                  </Button>
                                                </Box>
                                              </Grid>
                                              <TabContext value={screensvalue}>
                                                <Grid container spacing={2}>
                                                  <Grid item md={12}>
                                                    <TabList
                                                      onChange={screensChange}
                                                      aria-label="basic tabs example"
                                                      className="custom-tab"
                                                    >
                                                      {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                                                      <Tab
                                                        label="Audi 1"
                                                        value="1"
                                                      />
                                                      <Tab
                                                        label="Audi 2"
                                                        value="2"
                                                      />
                                                      <Tab
                                                        label="Audi 3"
                                                        value="3"
                                                      />
                                                    </TabList>
                                                  </Grid>
                                                  <Grid item md={12}>
                                                    <TabPanel value="1">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="2">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 2
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                    <TabPanel value="3">
                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Name
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              Audi 3
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Format
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              3D
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Dimension
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              70 ft
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Screen Info
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              HD
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Rows
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              9
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat Start
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              1
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Seat End
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          md="3"
                                                          className="table-border"
                                                        >
                                                          <Box
                                                            md="4"
                                                            textAlign={"center"}
                                                            padding={1}
                                                          >
                                                            <Typography
                                                              component="p"
                                                              className="transport-details-title"
                                                            >
                                                              Total Seats
                                                            </Typography>
                                                            <Typography
                                                              component="p"
                                                              className="transport-detail-value"
                                                            >
                                                              120
                                                            </Typography>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </TabPanel>
                                                  </Grid>
                                                </Grid>
                                              </TabContext>

                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SYSTEM DETAILS
                                                </Typography>
                                              </Box>
                                              <Grid container mt={2}>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      Sound Info
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Dolby Atmos
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      System Name
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      JBL
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                                <Grid
                                                  item
                                                  md="4"
                                                  className="table-border"
                                                >
                                                  <Box
                                                    md="4"
                                                    textAlign={"center"}
                                                    padding={1}
                                                  >
                                                    <Typography
                                                      component="p"
                                                      className="transport-details-title"
                                                    >
                                                      System Specs
                                                    </Typography>
                                                    <Typography
                                                      component="p"
                                                      className="transport-detail-value"
                                                    >
                                                      Crystal Clear Sound
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  SCREEN UTILIZATION
                                                </Typography>
                                              </Box>
                                              <Grid container>
                                                <Grid item md={12}>
                                                  <MemberTable
                                                    columns={screenutilcolumns}
                                                    options={options}
                                                    data={screenutildata}
                                                  />
                                                </Grid>
                                              </Grid>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  INSPECTION LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref1.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  EQUIPMENT INSPECTION LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref2.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  CLEANING LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref3.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  MAINTENANCE LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref6.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                              <Divider></Divider>
                                              <Box
                                                className="flex-between w-100"
                                                mt={2}
                                              >
                                                <Typography
                                                  className="font-bold"
                                                  component="h4"
                                                >
                                                  COMPLAINCES LOG
                                                </Typography>
                                                <Button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    ref7.current.log()
                                                  }
                                                >
                                                  View
                                                </Button>
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </TabPanel>
                                    <TabPanel
                                      value="2"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="3"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="4"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                  </Grid>
                                </Grid>
                              </TabContext>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
        <InspectionLogModal ref={ref1} />
        <EquipmentInspectionLog ref={ref2} />
        <CleaningLog ref={ref3} />
        <AddScreensConfiguratorModal ref={ref8} />
        <AddScreenConfiguratorLocationModal ref={ref5} />
        <MaintainanceLog ref={ref6} />
        <ComplianceLog ref={ref7} />
      </Box>
    </>
  );
}
