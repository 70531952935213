import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Breadcrumbs,
  Link,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import logo from "../images/logo-white.png";
import GroupedButtons from "./GroupedButtons";
import globe from "../images/globe.png";
import cart from "../images/cart.svg";
import notification from "../images/notification.png";
import profile from "../images/user.png";
import back from "../images/back.png";
import store from "../images/store.png";
import delivery from "../images/delivery-time.png";
import walmart from "../images/resto1.jpg";
import MemberTable from "../Customer/MemberTable";
import EditModal from "./EditModal";
import PayAccordion from "./PayAccordian";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OptionsInvoice = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Invoice() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "SKU Id",
    "Item Name",
    "Quantity",
    "Price",
    "Total Price",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Chicken Biryani", "10 kg", "Rs 100", "Rs 100"],
    ["#258974", "Garlic Prawn", "10 kg", "Rs 100", "Rs 100"],
    ["#258974", "Butter Naan", "10 kg", "Rs 100", "Rs 100"],
    ["#258974", "Kadai Paneer", "10 kg", "Rs 100", "Rs 100"],
    ["#258974", "Murg Malai Kebab", "10 kg", "Rs 100", "Rs 100"],
    ["#258974", "Muton Biryani", "10 kg", "Rs 100", "Rs 100"],
    ["", "", "", "Subtotal", "Rs 400"],
    ["", "", "", "taxes", "Rs 100"],
    ["", "", "", "Discount", "Rs 50"],
    ["", "", "", "Total", "Rs 400"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };

  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // constuctor();
  // {
  //   this.routeChange = this.routeChange.bind(this);
  // }

  // routeChange();
  // {
  //   let path = "./Procurement/Pay";
  //   this.props.history.push(path);
  // }
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid item xs="12">
            <Box className="w-100 text-center h-50">
              <Typography component="p">
                Shop Wholesale online from 85,000 brands
              </Typography>
            </Box>
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <SearchBar className="search-bar" />
                  </Box>
                  <Box className="icons bredcum-icons">
                    <Box
                      component="img"
                      src={globe}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      EN-GB |
                    </Typography>
                    <ShoppingCartIcon />
                    <NotificationsIcon />

                    <PersonIcon onClick={() => ref.current.log()} />
                    {/* <Button variant="filled" className="invite-supplier-btn">
                      INVITE SUPPLIER
                    </Button> */}
                  </Box>
                </Box>
                <Box className="breadcrumb-sec">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="/Procurement/Bazaar"
                    >
                      <Box component="img" src={back} />
                    </Link>
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Typography color="text.primary">
                      Product Detail View
                    </Typography>
                  </Breadcrumbs>
                </Box>
              </Grid>
            </Grid>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item md={8}>
                  <Card className="bgm-gray">
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={2}>
                          <Box
                            component="img"
                            src={walmart}
                            className="wx-100"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Box>
                            <Typography component="h5" className="h5" ml={2}>
                              Zeeshan Apna Hyderabadi
                            </Typography>
                            <Box ml={2}>
                              <Typography component="span" className="span">
                                D.no : 22-15-47/1, Panjaguta Colony
                              </Typography>
                              <br />
                              <Typography component="span" className="span">
                                Hyderbad, Telangana
                              </Typography>
                              <br />
                              <Typography component="span" className="span">
                                Contact : +91 - 9988776610
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3}>
                          <Typography component="p">Rs. 400</Typography>
                          <Button
                            href="/procurement/Pay"
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn bg-white"
                            mr={2}
                          >
                            Pay
                          </Button>
                        </Grid>
                      </Grid>
                      <Box className="member-table bold-data" mt={2}>
                        <MemberTable
                          columns={ItemColumns}
                          data={customerData}
                          options={OptionsInvoice}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  <Card className="bgm-gray">
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={2}>
                          <Box
                            component="img"
                            src={walmart}
                            className="wx-100"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Box>
                            <Typography component="h5" className="h5" ml={2}>
                              Paradise Multi-Cuisine
                            </Typography>
                            <Box ml={2}>
                              <Typography component="span" className="span">
                                D.no : 22-15-47/1, Panjaguta Colony
                              </Typography>
                              <br />
                              <Typography component="span" className="span">
                                Hyderbad, Telangana
                              </Typography>
                              <br />
                              <Typography component="span" className="span">
                                Contact : +91 - 9988776610
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3}>
                          <Typography component="p">Rs. 400</Typography>
                          <Button
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn bg-white"
                            mr={2}
                          >
                            Pay
                          </Button>
                        </Grid>
                      </Grid>
                      <Box className="member-table bold-data" mt={2}>
                        <MemberTable
                          columns={ItemColumns}
                          data={customerData}
                          options={OptionsInvoice}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  <Card className="bgm-gray">
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={2}>
                          <Box
                            component="img"
                            src={walmart}
                            className="wx-100"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Box>
                            <Typography component="h5" className="h5" ml={2}>
                              Arabian Nights
                            </Typography>
                            <Box ml={2}>
                              <Typography component="span" className="span">
                                D.no : 22-15-47/1, Panjaguta Colony
                              </Typography>
                              <br />
                              <Typography component="span" className="span">
                                Hyderbad, Telangana
                              </Typography>
                              <br />
                              <Typography component="span" className="span">
                                Contact : +91 - 9988776610
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3}>
                          <Typography component="p">Rs. 400</Typography>
                          <Button
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn bg-white"
                            mr={2}
                          >
                            Pay
                          </Button>
                        </Grid>
                      </Grid>
                      <Box className="member-table bold-data" mt={2}>
                        <MemberTable
                          columns={ItemColumns}
                          data={customerData}
                          options={OptionsInvoice}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4}>
                  {false ? (
                    <>
                      <Box className="border-dashed mb-15">
                        <Box className="flex-between">
                          <Typography component="p">
                            Delivery Address:
                          </Typography>
                          <Typography component="label">
                            Ram Villa, CBM Compound, Vishakhapatnam
                          </Typography>
                        </Box>
                        <Box className="flex-between" mt={2}>
                          <Typography component="p">Date and Time:</Typography>
                          <Typography component="label">
                            07 Mar, 2023 12:54PM
                          </Typography>
                        </Box>
                      </Box>
                      <PayAccordion />
                    </>
                  ) : (
                    ""
                  )}

                  <Card className="mb-15">
                    <CardContent>
                      <Box className="flex-between">
                        <Typography component="h4">Invoice Address</Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn bg-white"
                          mr={2}
                          onClick={() => ref3.current.log()}
                        >
                          Change
                        </Button>
                      </Box>
                      <Typography component="p" className="font-13">
                        Ram Villa, CBM Compound, Vishakhapatnam, Ram Villa, CBM
                        Compound, Vishakhapatnam
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card className="mb-15">
                    <CardContent>
                      <Box className="flex-between">
                        <Typography component="h4">Delivery Address</Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn bg-white"
                          mr={2}
                          onClick={() => ref3.current.log()}
                        >
                          Change
                        </Button>
                      </Box>
                      <Typography component="p" className="font-13">
                        Ram Villa, CBM Compound, Vishakhapatnam, Ram Villa, CBM
                        Compound, Vishakhapatnam
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card mb={2}>
                    <CardContent className="customer_information">
                      <Box className="flex-between">
                        <Typography component="h4">Contact Details</Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn bg-white"
                          mr={2}
                          onClick={() => ref3.current.log()}
                        >
                          Change
                        </Button>
                      </Box>
                      <Box mt={1}>
                        <Typography component="p">Name</Typography>
                        <Typography component="label">Santosh</Typography>
                      </Box>
                      {/* <Box className="information-div">
                        <Typography component="label">Retailer Type</Typography>
                        <Typography>Groceries</Typography>
                      </Box> */}
                      <Box mt={1}>
                        <Typography component="p">Mobile No.</Typography>
                        <Typography component="label">7075554800</Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography component="p">Email</Typography>
                        <Typography component="label">
                          santhosh@gmail.com
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography component="p">Designation</Typography>
                        <Typography component="label">Manager</Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box className="p-20"></Box>
            <Box></Box>
            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <EditModal ref={ref3} />
      </Box>
    </>
  );
}
