import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Switch,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControlLabel } from "@mui/material";
import AddMovieModal from "./AddMovieModal";
import movieBanner from "../images/movie-banner.jpeg";
import MovieManagementViewMoreModal from "./MovieManagementViewMoreModal";

const year = ["2023", "2022", "2021", "2020"];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const transactionstatementcolumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Amount",
  "Payout Date",
  "Payout Mode",
  "Payment CNF ID",
];
const transactionstatementdata = [
  ["12-08-2023", "15-08-2023", "₹2099", "15-08-2023", "UPI", "#239889498"],
];
const timeoffcolumns = [
  { name: "Date of Leave", options: { filterOptions: { fullWidth: true } } },
  "No of Days",
  "Reason",
  "Date Applied",
  "Status",
  "",
];
const timeoffdata = [
  [
    "01-10-2022",
    "3",
    "Sick",
    "01-10-2022",
    "Approved",
    <Button className="btn btn-outline-primary">View</Button>,
  ],
  [
    "01-10-2022",
    "1",
    "PTO",
    "01-10-2022",
    "Approved",
    <Button className="btn btn-outline-primary">View</Button>,
  ],
];
const pastmanagementColumns = [
  { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
  "Location",
  "Designation",
  "Date of Joining",
  "Last Working Day",
  "Reason",
  "Status",
];
const pastmanagementdata = [
  [
    "Andrew",
    "Debary",
    "Cashier",
    "05 July 2023",
    "14 July 2023",
    "Sick Leave",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  ],
  [
    "Zeus",
    "Debary",
    "Cashier",
    "05 July 2023",
    "14 July 2023",
    "Sick Leave",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  ],
];
const managementColumns = [
  { name: "Movie ID", options: { filterOptions: { fullWidth: true } } },
  "Movie Name",
  "Language",
  "Release Date",
  "End Date",
  "Status",
];
const managementdata = [
  [
    "MOV_1",
    "Kushi",
    "Telugu",
    "01/09/23",
    "01/10/23",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  ],
  [
    "MOV_2",
    "Jawaan",
    "Hindi",
    "01/09/23",
    "01/10/23",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  ],
];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const tstatementsColumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Transport",
  "Total Amount",
  "PayOut Date",
  "PayOut Mode",
  "Payment CNF ID",
  "Action",
  "",
  "",
];
const tstatementsdata = [
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
];

const tstatementsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Management() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ref4 = React.useRef();
  const ref1 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "end" }}
                      className="filters"
                    >
                      {/* <Box className="filters-display">
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">City</MenuItem>
                          <MenuItem>Hyderabad</MenuItem>
                          <MenuItem>Visakhapatnam</MenuItem>
                          <MenuItem>Guntur</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Location</MenuItem>
                          <MenuItem>Gachibowli</MenuItem>
                          <MenuItem>Hi-Tech City</MenuItem>
                          <MenuItem>Cyber Towers</MenuItem>
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">Screen</MenuItem>
                          <MenuItem>Screen 1</MenuItem>
                          <MenuItem>Screen 2</MenuItem>
                          <MenuItem>Screen 3</MenuItem>
                        </Select>
                      </Box> */}
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Button
                          variant="outlined"
                          className="btn-outline-primary btn-space"
                          onClick={() => ref4.current.log()}
                        >
                          Add Movie
                        </Button>
                        {/* <Button
                          variant="outlined"
                          className="btn-outline-primary btn-space"
                        >
                          List
                        </Button> */}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs="12" md="8" mt={3}>
                      <Card md="12">
                        <CardContent>
                          {/* <Grid container>
                            <Grid item md="12">
                              <Typography mb={2} className="t-heading">
                                Drivers
                              </Typography>
                            </Grid>
                          </Grid> */}
                          <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                            className="shift-shedule-tabs"
                            mb={1}
                          >
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Current" {...a11yProps(0)} />
                              <Tab label="Past" {...a11yProps(1)} />
                            </Tabs>
                          </Box>
                          <Box sx={{ height: 400, width: "100%" }}>
                            <TabPanel
                              value={value}
                              index={0}
                              className="custom-shift-tab"
                            >
                              <MemberTable
                                columns={managementColumns}
                                options={managementOptions}
                                data={managementdata}
                              />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                              <MemberTable
                                columns={pastmanagementColumns}
                                options={managementOptions}
                                data={pastmanagementdata}
                              />
                            </TabPanel>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs="12" md="4" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container>
                            <Grid item md={12}>
                              <Box
                                component="img"
                                className="movie-banner"
                                src={movieBanner}
                              ></Box>
                            </Grid>
                          </Grid>
                          <Grid container mt={1}>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Censor Rating
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  U/A
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Release Date
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Sept 01, 2023
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Duration
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  2hrs 20mins
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid
                              item
                              md={12}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Available In
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Telugu, Malayalam, Tamil, Kannada, Hindi
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid
                              item
                              md={12}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Cast & Crew
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Vijay Devarakonda, Samantha, Murali Sharma,
                                  Shiva Nirvana, Vennala Kishore
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid
                              item
                              md={12}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Director
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Shiva Nivana
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid
                              item
                              md={12}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Synopsis
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  A couple who are predicted to have a failed
                                  marriage, decide to become ideal partners for
                                  each other. Will it work out or will they
                                  realize that their parents were right?
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md={6}>
                              <Button
                                className="btn btn-outline-primary movie-edit-sch-btns"
                                onClick={() => ref1.current.log()}
                              >
                                Edit
                              </Button>
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                className="btn btn-outline-primary movie-edit-sch-btns"
                                href="/movies/movieScheduling"
                              >
                                Scheduling
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddMovieModal ref={ref4} />
        <MovieManagementViewMoreModal ref={ref1} />
      </Box>
    </>
  );
}
