import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";

import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Bredcrum from "../Bredcrum";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SeatMatrix from "./SeatMatrix";
import Stack from "@mui/material/Stack";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function MoviePublishing() {
  const handleClick = () => {
    setShowState(!showState);
  };
  const handleChange1 = (event, newValue) => {
    setLocation(newValue);
  };
  const [value, setValue] = React.useState("1");
  const [moviename, setMovieName] = React.useState("1");
  const [location, setLocation] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const moviehandlechange = (event, newValue) => {
    setMovieName(newValue);
  };
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref1 = React.useRef();
  const ref2 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: true,
  };

  const screenutilcolumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Movie Name",
    "Show Time",
    "Occupancy %",
    "",
    "",
  ];

  const screenutildata = [
    [
      "2/08/2023",
      "MEG 2",
      "11:00 AM",
      "70%",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
    [
      "5/08/2023",
      "Gaddar 2",
      "03:00 PM",
      "50%",
      <Button className="btn btn-outline-primary">Edit</Button>,
      <Button className="btn btn-outline-primary">Delete</Button>,
    ],
  ];

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item md={12}>
                    <Card>
                      <CardContent>
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <TabContext value={value}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                              className="tabs-box"
                            >
                              <TabList
                                onChange={handleChange}
                                aria-label="lab API tabs example"
                              >
                                <Tab label="Hyderabad" value="1" />
                                <Tab label="Delhi" value="2" />
                                <Tab label="Mumbai" value="3" />
                                <Tab label="Vizag" value="4" />
                                <Button className="btn-add-city">
                                  Add City
                                </Button>
                              </TabList>
                            </Box>
                            <TabPanel
                              value="1"
                              className="tab-panel-description"
                            >
                              {showState ? (
                                ""
                              ) : (
                                <Grid container spacing={2}>
                                  <Grid item md={2}>
                                    <Box marginBottom={1}>
                                      <SearchBar className="search-bar" />
                                    </Box>
                                    <Box textAlign={"center"} marginBottom={1}>
                                      <FormGroup>
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Typography className="movie-switch">
                                            Movie
                                          </Typography>
                                          <AntSwitch
                                            onClick={handleClick}
                                            defaultChecked
                                            inputProps={{
                                              "aria-label": "ant design",
                                            }}
                                          />
                                          <Typography className="screen-switch">
                                            Screen
                                          </Typography>
                                        </Stack>
                                      </FormGroup>
                                    </Box>
                                    <Accordion defaultExpanded={true}>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Secunderabad
                                      </AccordionSummary>
                                      <AccordionDetails className="vertical-tabs-box">
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            className="vertical-tabs-selection custom-btn-color"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Kukatpally
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Gachibowli
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Jubilee Hills
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Audi 1" value="1" />
                                            <Tab label="Audi 2" value="2" />
                                            <Tab label="Audi 3" value="3" />
                                            <Tab label="Audi 4" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Button
                                      className="btn btn-outline-primary w-100 mt-15"
                                      // onClick={() => ref5.current.log()}
                                    >
                                      Add Location
                                    </Button>
                                  </Grid>
                                  <Grid item md={10} className="color-attach">
                                    <TabPanel
                                      value="1"
                                      className="tab-panel-sub-description"
                                    >
                                      <TabContext value={moviename}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="movies-tabs-box"
                                        >
                                          <TabList
                                            onChange={moviehandlechange}
                                            aria-label="lab API tabs example"
                                          >
                                            <Tab
                                              label="Kushi - 10:00 AM"
                                              value="1"
                                            />
                                            <Tab
                                              label="Kushi - 01:30 PM"
                                              value="2"
                                            />
                                            <Tab
                                              label="Kushi - 04:00 PM"
                                              value="3"
                                            />
                                          </TabList>
                                        </Box>
                                        <TabPanel
                                          value="1"
                                          className="movies-tab-panel"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                      <SeatMatrix />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                      <Box>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                defaultChecked
                                                              />
                                                            }
                                                            label="Online Website"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Ticket Counter POS"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Book My Show"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="JustTickets"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="PayTm"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="WhatNxt"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Corporate Hold"
                                                            size="small"
                                                          />
                                                        </FormGroup>
                                                      </Box>
                                                      <Box>
                                                        <FormControlLabel
                                                          control={<Checkbox />}
                                                          label="Apply to All"
                                                        />
                                                      </Box>
                                                      <Box>
                                                        <Button className="btn btn-outline-primary">
                                                          Publish
                                                        </Button>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="movies-tab-panel"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                      <SeatMatrix />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                      <Box>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                defaultChecked
                                                              />
                                                            }
                                                            label="Online Website"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Ticket Counter POS"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Book My Show"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="JustTickets"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="PayTm"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="WhatNxt"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Corporate Hold"
                                                            size="small"
                                                          />
                                                        </FormGroup>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="movies-tab-panel"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                      <SeatMatrix />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                      <Box>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                defaultChecked
                                                              />
                                                            }
                                                            label="Online Website"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Ticket Counter POS"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Book My Show"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="JustTickets"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="PayTm"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="WhatNxt"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Corporate Hold"
                                                            size="small"
                                                          />
                                                        </FormGroup>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                      </TabContext>
                                    </TabPanel>
                                    <TabPanel
                                      value="2"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="3"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="4"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                  </Grid>
                                </Grid>
                              )}
                              {showState && (
                                <Grid container spacing={2}>
                                  <Grid item md={2}>
                                    <Box marginBottom={1}>
                                      <SearchBar className="search-bar" />
                                    </Box>
                                    <Box textAlign={"center"} marginBottom={1}>
                                      <FormGroup>
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Typography className="movie-switch">
                                            Movie
                                          </Typography>
                                          <AntSwitch
                                            onClick={handleClick}
                                            inputProps={{
                                              "aria-label": "ant design",
                                            }}
                                          />
                                          <Typography className="screen-switch">
                                            Screen
                                          </Typography>
                                        </Stack>
                                      </FormGroup>
                                    </Box>
                                    <Accordion defaultExpanded={true}>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Secunderabad
                                      </AccordionSummary>
                                      <AccordionDetails className="vertical-tabs-box">
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            className="vertical-tabs-selection custom-btn-color"
                                          >
                                            <Tab label="Kushi" value="1" />
                                            <Tab label="Jawan" value="2" />
                                            <Tab label="NUN II" value="3" />
                                            <Tab label="Meg 2" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Kukatpally
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Kushi" value="1" />
                                            <Tab label="Jawan" value="2" />
                                            <Tab label="NUN II" value="3" />
                                            <Tab label="Meg 2" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Gachibowli
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Kushi" value="1" />
                                            <Tab label="Jawan" value="2" />
                                            <Tab label="NUN II" value="3" />
                                            <Tab label="Meg 2" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={
                                          <svg
                                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                            focusable="false"
                                            aria-hidden="true"
                                            viewBox="0 0 24 24"
                                            data-testid="ArrowDropUpIcon"
                                          >
                                            <path d="m7 16 4-7 4 7z"></path>
                                          </svg>
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="accordian-header"
                                      >
                                        Jubilee Hills
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                        >
                                          <TabList
                                            orientation="vertical"
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                          >
                                            <Tab label="Kushi" value="1" />
                                            <Tab label="Jawan" value="2" />
                                            <Tab label="NUN II" value="3" />
                                            <Tab label="Meg 2" value="4" />
                                          </TabList>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                  <Grid item md={10} className="color-attach">
                                    <TabPanel
                                      value="1"
                                      className="tab-panel-sub-description"
                                    >
                                      <TabContext value={moviename}>
                                        <Box
                                          sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                          }}
                                          className="movies-tabs-box"
                                        >
                                          <TabList
                                            onChange={moviehandlechange}
                                            aria-label="lab API tabs example"
                                          >
                                            <Tab
                                              label="Audi 1 - 10:00 AM"
                                              value="1"
                                            />
                                            <Tab
                                              label="Audi 2 - 01:30 PM"
                                              value="2"
                                            />
                                            <Tab
                                              label="Audi 3 - 04:00 PM"
                                              value="3"
                                            />
                                          </TabList>
                                        </Box>
                                        <TabPanel
                                          value="1"
                                          className="movies-tab-panel"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                      <SeatMatrix />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                      <Box>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                defaultChecked
                                                              />
                                                            }
                                                            label="Online Website"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Ticket Counter POS"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Book My Show"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="JustTickets"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="PayTm"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="WhatNxt"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Corporate Hold"
                                                            size="small"
                                                          />
                                                        </FormGroup>
                                                      </Box>
                                                      <Box>
                                                        <FormControlLabel
                                                          control={<Checkbox />}
                                                          label="Apply to All"
                                                        />
                                                      </Box>
                                                      <Box>
                                                        <Button className="btn btn-outline-primary">
                                                          Publish
                                                        </Button>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="2"
                                          className="movies-tab-panel"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                      <SeatMatrix />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                      <Box>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                defaultChecked
                                                              />
                                                            }
                                                            label="Online Website"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Ticket Counter POS"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Book My Show"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="JustTickets"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="PayTm"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="WhatNxt"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Corporate Hold"
                                                            size="small"
                                                          />
                                                        </FormGroup>
                                                      </Box>
                                                      <Box>
                                                        <FormControlLabel
                                                          control={<Checkbox />}
                                                          label="Apply to All"
                                                        />
                                                      </Box>
                                                      <Box>
                                                        <Button className="btn btn-outline-primary">
                                                          Publish
                                                        </Button>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                        <TabPanel
                                          value="3"
                                          className="movies-tab-panel"
                                        >
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <Card>
                                                <CardContent>
                                                  <Grid container spacing={2}>
                                                    <Grid item md={8}>
                                                      <SeatMatrix />
                                                    </Grid>
                                                    <Grid item md={4}>
                                                      <Box>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                defaultChecked
                                                              />
                                                            }
                                                            label="Online Website"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Ticket Counter POS"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Book My Show"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="JustTickets"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="PayTm"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="WhatNxt"
                                                            size="small"
                                                          />
                                                          <FormControlLabel
                                                            control={<Switch />}
                                                            label="Corporate Hold"
                                                            size="small"
                                                          />
                                                        </FormGroup>
                                                      </Box>
                                                      <Box>
                                                        <FormControlLabel
                                                          control={<Checkbox />}
                                                          label="Apply to All"
                                                        />
                                                      </Box>
                                                      <Box>
                                                        <Button className="btn btn-outline-primary">
                                                          Publish
                                                        </Button>
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </TabPanel>
                                      </TabContext>
                                    </TabPanel>
                                    <TabPanel
                                      value="2"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="3"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                    <TabPanel
                                      value="4"
                                      className="tab-panel-sub-description"
                                    ></TabPanel>
                                  </Grid>
                                </Grid>
                              )}
                            </TabPanel>
                            <TabPanel
                              value="2"
                              className="tab-panel-description"
                            >
                              <TabContext value={location}>
                                {showState ? (
                                  ""
                                ) : (
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      <Box marginBottom={1}>
                                        <SearchBar className="search-bar" />
                                      </Box>
                                      <Box
                                        textAlign={"center"}
                                        marginBottom={1}
                                      >
                                        <FormGroup>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Typography className="movie-switch">
                                              Movie
                                            </Typography>
                                            <AntSwitch
                                              onClick={handleClick}
                                              defaultChecked
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                            />
                                            <Typography className="screen-switch">
                                              Screen
                                            </Typography>
                                          </Stack>
                                        </FormGroup>
                                      </Box>
                                      <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          New Delhi
                                        </AccordionSummary>
                                        <AccordionDetails className="vertical-tabs-box">
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                              className="vertical-tabs-selection custom-btn-color"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Mehrauli
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Dwarka
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Delhi Contonment
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Button
                                        className="btn btn-outline-primary w-100 mt-15"
                                        // onClick={() => ref5.current.log()}
                                      >
                                        Add Location
                                      </Button>
                                    </Grid>
                                    <Grid item md={10} className="color-attach">
                                      <TabPanel
                                        value="1"
                                        className="tab-panel-sub-description"
                                      >
                                        <TabContext value={moviename}>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                            className="movies-tabs-box"
                                          >
                                            <TabList
                                              onChange={moviehandlechange}
                                              aria-label="lab API tabs example"
                                            >
                                              <Tab
                                                label="Kushi - 10:00 AM"
                                                value="1"
                                              />
                                              <Tab
                                                label="Kushi - 01:30 PM"
                                                value="2"
                                              />
                                              <Tab
                                                label="Kushi - 04:00 PM"
                                                value="3"
                                              />
                                            </TabList>
                                          </Box>
                                          <TabPanel
                                            value="1"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="2"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="3"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                        </TabContext>
                                      </TabPanel>
                                      <TabPanel
                                        value="2"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="3"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="4"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                    </Grid>
                                  </Grid>
                                )}
                                {showState && (
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      <Box marginBottom={1}>
                                        <SearchBar className="search-bar" />
                                      </Box>
                                      <Box
                                        textAlign={"center"}
                                        marginBottom={1}
                                      >
                                        <FormGroup>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Typography className="movie-switch">
                                              Movie
                                            </Typography>
                                            <AntSwitch
                                              onClick={handleClick}
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                            />
                                            <Typography className="screen-switch">
                                              Screen
                                            </Typography>
                                          </Stack>
                                        </FormGroup>
                                      </Box>
                                      <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          New Delhi
                                        </AccordionSummary>
                                        <AccordionDetails className="vertical-tabs-box">
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                              className="vertical-tabs-selection custom-btn-color"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Mehrauli
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Dwarka
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Delhi Contonment
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                    <Grid item md={10} className="color-attach">
                                      <TabPanel
                                        value="1"
                                        className="tab-panel-sub-description"
                                      >
                                        <TabContext value={moviename}>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                            className="movies-tabs-box"
                                          >
                                            <TabList
                                              onChange={moviehandlechange}
                                              aria-label="lab API tabs example"
                                            >
                                              <Tab
                                                label="Audi 1 - 10:00 AM"
                                                value="1"
                                              />
                                              <Tab
                                                label="Audi 2 - 01:30 PM"
                                                value="2"
                                              />
                                              <Tab
                                                label="Audi 3 - 04:00 PM"
                                                value="3"
                                              />
                                            </TabList>
                                          </Box>
                                          <TabPanel
                                            value="1"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="2"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="3"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                        </TabContext>
                                      </TabPanel>
                                      <TabPanel
                                        value="2"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="3"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="4"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                    </Grid>
                                  </Grid>
                                )}
                              </TabContext>
                            </TabPanel>
                            <TabPanel
                              value="3"
                              className="tab-panel-description"
                            >
                              <TabContext value={location}>
                                {showState ? (
                                  ""
                                ) : (
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      <Box marginBottom={1}>
                                        <SearchBar className="search-bar" />
                                      </Box>
                                      <Box
                                        textAlign={"center"}
                                        marginBottom={1}
                                      >
                                        <FormGroup>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Typography className="movie-switch">
                                              Movie
                                            </Typography>
                                            <AntSwitch
                                              onClick={handleClick}
                                              defaultChecked
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                            />
                                            <Typography className="screen-switch">
                                              Screen
                                            </Typography>
                                          </Stack>
                                        </FormGroup>
                                      </Box>
                                      <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Marine Drive
                                        </AccordionSummary>
                                        <AccordionDetails className="vertical-tabs-box">
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                              className="vertical-tabs-selection custom-btn-color"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Chowpatti Beach
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Andheri
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Borivali East
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Button
                                        className="btn btn-outline-primary w-100 mt-15"
                                        // onClick={() => ref5.current.log()}
                                      >
                                        Add Location
                                      </Button>
                                    </Grid>
                                    <Grid item md={10} className="color-attach">
                                      <TabPanel
                                        value="1"
                                        className="tab-panel-sub-description"
                                      >
                                        <TabContext value={moviename}>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                            className="movies-tabs-box"
                                          >
                                            <TabList
                                              onChange={moviehandlechange}
                                              aria-label="lab API tabs example"
                                            >
                                              <Tab
                                                label="Kushi - 10:00 AM"
                                                value="1"
                                              />
                                              <Tab
                                                label="Kushi - 01:30 PM"
                                                value="2"
                                              />
                                              <Tab
                                                label="Kushi - 04:00 PM"
                                                value="3"
                                              />
                                            </TabList>
                                          </Box>
                                          <TabPanel
                                            value="1"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="2"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="3"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                        </TabContext>
                                      </TabPanel>
                                      <TabPanel
                                        value="2"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="3"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="4"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                    </Grid>
                                  </Grid>
                                )}
                                {showState && (
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      <Box marginBottom={1}>
                                        <SearchBar className="search-bar" />
                                      </Box>
                                      <Box
                                        textAlign={"center"}
                                        marginBottom={1}
                                      >
                                        <FormGroup>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Typography className="movie-switch">
                                              Movie
                                            </Typography>
                                            <AntSwitch
                                              onClick={handleClick}
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                            />
                                            <Typography className="screen-switch">
                                              Screen
                                            </Typography>
                                          </Stack>
                                        </FormGroup>
                                      </Box>
                                      <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Marine Drive
                                        </AccordionSummary>
                                        <AccordionDetails className="vertical-tabs-box">
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                              className="vertical-tabs-selection custom-btn-color"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Chowpatti Beach
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Andheri
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Borivali East
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                    <Grid item md={10} className="color-attach">
                                      <TabPanel
                                        value="1"
                                        className="tab-panel-sub-description"
                                      >
                                        <TabContext value={moviename}>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                            className="movies-tabs-box"
                                          >
                                            <TabList
                                              onChange={moviehandlechange}
                                              aria-label="lab API tabs example"
                                            >
                                              <Tab
                                                label="Audi 1 - 10:00 AM"
                                                value="1"
                                              />
                                              <Tab
                                                label="Audi 2 - 01:30 PM"
                                                value="2"
                                              />
                                              <Tab
                                                label="Audi 3 - 04:00 PM"
                                                value="3"
                                              />
                                            </TabList>
                                          </Box>
                                          <TabPanel
                                            value="1"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="2"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="3"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                        </TabContext>
                                      </TabPanel>
                                      <TabPanel
                                        value="2"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="3"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="4"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                    </Grid>
                                  </Grid>
                                )}
                              </TabContext>
                            </TabPanel>
                            <TabPanel
                              value="4"
                              className="tab-panel-description"
                            >
                              <TabContext value={location}>
                                {showState ? (
                                  ""
                                ) : (
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      <Box marginBottom={1}>
                                        <SearchBar className="search-bar" />
                                      </Box>
                                      <Box
                                        textAlign={"center"}
                                        marginBottom={1}
                                      >
                                        <FormGroup>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Typography className="movie-switch">
                                              Movie
                                            </Typography>
                                            <AntSwitch
                                              onClick={handleClick}
                                              defaultChecked
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                            />
                                            <Typography className="screen-switch">
                                              Screen
                                            </Typography>
                                          </Stack>
                                        </FormGroup>
                                      </Box>
                                      <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          RTC Complex
                                        </AccordionSummary>
                                        <AccordionDetails className="vertical-tabs-box">
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                              className="vertical-tabs-selection custom-btn-color"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Maddilapalem
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Madhurawada
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Yendada
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Audi 1" value="1" />
                                              <Tab label="Audi 2" value="2" />
                                              <Tab label="Audi 3" value="3" />
                                              <Tab label="Audi 4" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Button
                                        className="btn btn-outline-primary w-100 mt-15"
                                        // onClick={() => ref5.current.log()}
                                      >
                                        Add Location
                                      </Button>
                                    </Grid>
                                    <Grid item md={10} className="color-attach">
                                      <TabPanel
                                        value="1"
                                        className="tab-panel-sub-description"
                                      >
                                        <TabContext value={moviename}>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                            className="movies-tabs-box"
                                          >
                                            <TabList
                                              onChange={moviehandlechange}
                                              aria-label="lab API tabs example"
                                            >
                                              <Tab
                                                label="Kushi - 10:00 AM"
                                                value="1"
                                              />
                                              <Tab
                                                label="Kushi - 01:30 PM"
                                                value="2"
                                              />
                                              <Tab
                                                label="Kushi - 04:00 PM"
                                                value="3"
                                              />
                                            </TabList>
                                          </Box>
                                          <TabPanel
                                            value="1"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="2"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="3"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                        </TabContext>
                                      </TabPanel>
                                      <TabPanel
                                        value="2"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="3"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="4"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                    </Grid>
                                  </Grid>
                                )}
                                {showState && (
                                  <Grid container spacing={2}>
                                    <Grid item md={2}>
                                      <Box marginBottom={1}>
                                        <SearchBar className="search-bar" />
                                      </Box>
                                      <Box
                                        textAlign={"center"}
                                        marginBottom={1}
                                      >
                                        <FormGroup>
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                          >
                                            <Typography className="movie-switch">
                                              Movie
                                            </Typography>
                                            <AntSwitch
                                              onClick={handleClick}
                                              inputProps={{
                                                "aria-label": "ant design",
                                              }}
                                            />
                                            <Typography className="screen-switch">
                                              Screen
                                            </Typography>
                                          </Stack>
                                        </FormGroup>
                                      </Box>
                                      <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          RTC COmplex
                                        </AccordionSummary>
                                        <AccordionDetails className="vertical-tabs-box">
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                              className="vertical-tabs-selection custom-btn-color"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Maddilapalem
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Madhurawada
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={
                                            <svg
                                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                              focusable="false"
                                              aria-hidden="true"
                                              viewBox="0 0 24 24"
                                              data-testid="ArrowDropUpIcon"
                                            >
                                              <path d="m7 16 4-7 4 7z"></path>
                                            </svg>
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Yendada
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                          >
                                            <TabList
                                              orientation="vertical"
                                              onChange={handleChange}
                                              aria-label="Vertical tabs example"
                                            >
                                              <Tab label="Kushi" value="1" />
                                              <Tab label="Jawan" value="2" />
                                              <Tab label="NUN II" value="3" />
                                              <Tab label="Meg 2" value="4" />
                                            </TabList>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                    <Grid item md={10} className="color-attach">
                                      <TabPanel
                                        value="1"
                                        className="tab-panel-sub-description"
                                      >
                                        <TabContext value={moviename}>
                                          <Box
                                            sx={{
                                              borderBottom: 1,
                                              borderColor: "divider",
                                            }}
                                            className="movies-tabs-box"
                                          >
                                            <TabList
                                              onChange={moviehandlechange}
                                              aria-label="lab API tabs example"
                                            >
                                              <Tab
                                                label="Audi 1 - 10:00 AM"
                                                value="1"
                                              />
                                              <Tab
                                                label="Audi 2 - 01:30 PM"
                                                value="2"
                                              />
                                              <Tab
                                                label="Audi 3 - 04:00 PM"
                                                value="3"
                                              />
                                            </TabList>
                                          </Box>
                                          <TabPanel
                                            value="1"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="2"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                          <TabPanel
                                            value="3"
                                            className="movies-tab-panel"
                                          >
                                            <Grid container spacing={2}>
                                              <Grid item md={12}>
                                                <Card>
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      <Grid item md={8}>
                                                        <SeatMatrix />
                                                      </Grid>
                                                      <Grid item md={4}>
                                                        <Box>
                                                          <FormGroup>
                                                            <FormControlLabel
                                                              control={
                                                                <Switch
                                                                  defaultChecked
                                                                />
                                                              }
                                                              label="Online Website"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Ticket Counter POS"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Book My Show"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="JustTickets"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="PayTm"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="WhatNxt"
                                                              size="small"
                                                            />
                                                            <FormControlLabel
                                                              control={
                                                                <Switch />
                                                              }
                                                              label="Corporate Hold"
                                                              size="small"
                                                            />
                                                          </FormGroup>
                                                        </Box>
                                                        <Box>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox />
                                                            }
                                                            label="Apply to All"
                                                          />
                                                        </Box>
                                                        <Box>
                                                          <Button className="btn btn-outline-primary">
                                                            Publish
                                                          </Button>
                                                        </Box>
                                                      </Grid>
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </TabPanel>
                                        </TabContext>
                                      </TabPanel>
                                      <TabPanel
                                        value="2"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="3"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                      <TabPanel
                                        value="4"
                                        className="tab-panel-sub-description"
                                      ></TabPanel>
                                    </Grid>
                                  </Grid>
                                )}
                              </TabContext>
                            </TabPanel>
                          </TabContext>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
