import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Switch,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { FormControlLabel, Radio } from "@mui/material";
import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import AddEmployeeModal from "./AddEmployeeModal";
import ManagementViewMoreModal from "./ManagementViewMoreModal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputLabel from "@mui/material/InputLabel";
import { Theme, useTheme } from "@mui/material/styles";
// import { useDemoData } from "@mui/x-data-grid-generator";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Admin",
  "Accounting",
  "Employee Management",
  "Menu",
  "KOT",
  "POS",
  "Phone Orders",
  "Procurement",
  "Promotions",
  "Orders",
];
const mnames = ["Scheduling", "Bookings", "Inspections", "Profile"];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const managementColumns = [
  { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
  "Designation",
  "Location",
  "Complaince",
  "Status",
];
const managementdata = [
  ["Andrew", "Driver", "Debary", "Yes", ""],
  ["Zeus", "Driver", "Debary", "Yes", ""],
];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const tstatementsColumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Transport",
  "Total Amount",
  "PayOut Date",
  "PayOut Mode",
  "Payment CNF ID",
  "Action",
  "",
  "",
];
const tstatementsdata = [
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
];

const tstatementsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const columns: GridColDef[] = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    editable: true,
  },
  {
    field: "totalTransport",
    headerName: "Total Transport",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "earnings",
    headerName: "Earnings",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "leaves",
    headerName: "Leaves",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "compliance",
    headerName: "Compliance",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const itemOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: true,
  selectableRows: false,
  confirmFilters: false,
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const ItemColumns = ["Shift Name", "Start Time", "End Time", ""];
const rolescolumns = ["Role Name", "Module", ""];
const rolesdate = [
  [
    "Cashier",
    "Accounting",
    <Button className="btn btn-outline-primary">Edit</Button>,
  ],
];
const payenginet1columns = ["Per Hour", "Per Week", "Per Month"];
const rolesdata = [
  [
    "Regular Shit",
    "10:00 AM",
    "08:00 PM",
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];

const servicecolumns = [
  "Incentive",
  "Applicable (Yes / No)",
  "Type (Flat / %)",
  "Fee",
  "",
];
const servicedata = [
  "Transport",
  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  "20%",
  <Button className="btn-outline-primary btn-space">Edit</Button>,
];
const top100Films = [{ title: "The Shawshank Redemption", year: 1994 }];

const orderItemData = [
  [
    "Regular Shit",
    "10:00 AM",
    "08:00 PM",
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];

const HolidayShiftData = [
  [
    "Sick Leave",
    "12-08-2023",
    "2%",
    "10%",
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];
const HolidayShiftColumns = [
  "Holiday Name",
  "Date",
  "Pricing Percentage",
  "Page Percentage",
  "",
];

const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function PaySetup() {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = React.useState(0);
  const ref4 = React.useRef();
  const ref1 = React.useRef();

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="custom-tabs"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="custom-tab"
                >
                  <Tab label="Setup" {...a11yProps(0)} />
                  {/* <Tab label="Pricing Engine" {...a11yProps(1)} /> */}
                  <Tab label="Pay Engine" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CardContent>
                <TabPanel value={value} index={0}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item md={6}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              SHIFTS
                            </Typography>
                          </Box>
                          <Grid container spacing={2} mb={2} alignItems="end">
                            <Grid item md={4}>
                              <FormControl className="w-100" rounded>
                                <Typography component="label">
                                  Shift Name
                                </Typography>
                                <OutlinedInput placeholder="" />
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Start Time
                                </Typography>
                                <OutlinedInput
                                  value=""
                                  type="time"
                                  placeholder="Hours"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  End Time
                                </Typography>
                                <OutlinedInput
                                  value=""
                                  type="time"
                                  placeholder="Hours"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={2}>
                              <Button
                                variant="outlined"
                                className="btn-outline-primary btn-space"
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={ItemColumns}
                                options={itemOptions}
                                data={orderItemData}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item md={6}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              ROLES
                            </Typography>
                          </Box>
                          <Grid container spacing={2} mb={2} alignItems="end">
                            <Grid item md={3}>
                              <FormControl className="w-100" rounded>
                                <Typography component="label">
                                  Role Name
                                </Typography>
                                <OutlinedInput placeholder="" />
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              {/* <FormControl className="w-100">
                                <Autocomplete
                                  className="custom-height-ac paysetup-modules"
                                  id="free-solo-demo"
                                  Services
                                  options={top100Films.map(
                                    (option) => option.title
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Modules"
                                      className="modules-multi-select"
                                    />
                                  )}
                                />
                              </FormControl> */}
                              <FormControl className="w-100">
                                <InputLabel
                                  id="demo-multiple-name-label"
                                  className="paysetup-modules"
                                >
                                  Modules For CMS
                                </InputLabel>
                                <Select
                                  className="custom-height-ac"
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  multiple
                                  value={personName}
                                  onChange={handleChangeMulti}
                                  input={<OutlinedInput label="Name" />}
                                  MenuProps={MenuProps}
                                >
                                  {names.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(name, personName, theme)}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              {/* <FormControl className="w-100">
                                <Autocomplete
                                  className="custom-height-ac paysetup-modules"
                                  id="free-solo-demo"
                                  Services
                                  options={top100Films.map(
                                    (option) => option.title
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Modules"
                                      className="modules-multi-select"
                                    />
                                  )}
                                />
                              </FormControl> */}
                              <FormControl className="w-100">
                                <InputLabel
                                  id="demo-multiple-name-label"
                                  className="paysetup-modules"
                                >
                                  Modules For Mobile APP
                                </InputLabel>
                                <Select
                                  className="custom-height-ac"
                                  labelId="demo-multiple-name-label"
                                  id="demo-multiple-name"
                                  multiple
                                  value={personName}
                                  onChange={handleChangeMulti}
                                  input={<OutlinedInput label="Name" />}
                                  MenuProps={MenuProps}
                                >
                                  {mnames.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      style={getStyles(name, personName, theme)}
                                    >
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Modules
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Dashboard
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Purchase Orders
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Retailers
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Accounting
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid> */}
                            <Grid item md={3}>
                              <Button
                                variant="outlined"
                                className="btn-outline-primary btn-space"
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={rolescolumns}
                                options={itemOptions}
                                data={rolesdate}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={2} mt={2}>
                    <Grid item md={6}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              HOLIDAY SHIFT
                            </Typography>
                          </Box>
                          <Grid container spacing={2} mb={2} alignItems="end">
                            <Grid item md={3}>
                              <FormControl className="w-100" rounded>
                                <Typography component="label">
                                  Holiday Name
                                </Typography>
                                <OutlinedInput placeholder="" />
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Date of Holiday
                                </Typography>
                                <OutlinedInput
                                  type="date"
                                  placeholder="Hours"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              <FormControl className="w-100" rounded>
                                <Typography component="label">
                                  Pricing Percentage
                                </Typography>
                                <OutlinedInput placeholder="" />
                              </FormControl>
                            </Grid>
                            <Grid item md={3}>
                              <FormControl className="w-100" rounded>
                                <Typography component="label">
                                  Pay Percentage
                                </Typography>
                                <OutlinedInput placeholder="" />
                              </FormControl>
                            </Grid>
                            <Grid item md={2}>
                              <Button
                                variant="outlined"
                                className="btn-outline-primary btn-space"
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={HolidayShiftColumns}
                                options={itemOptions}
                                data={HolidayShiftData}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid> */}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item md={5}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Roles
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Delivery Boy
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Assistant
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Shop Keeper
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Accountant
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Employees
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">Sai</MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Anil
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Manohar
                                    </MenuItem>

                                    <MenuItem value="kurnool">Sumit</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Shifts
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Regular Shift
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Night Shift
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Late Night Shift
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Afternoon Shift
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Wages Type
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Hourly Wage
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Daily Wage
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Weekly Wage
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Monthly Wage
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={2}>
                            <Grid item md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Pay Type
                                </Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="Suppliers"
                                >
                                  <MenuItem value="Suppliers">Select</MenuItem>
                                  <MenuItem value="car">Per Day</MenuItem>
                                  <MenuItem value="van">Per Hour</MenuItem>
                                  <MenuItem value="jeep">Per Week</MenuItem>
                                  <MenuItem value="truck">Per Month</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                              <FormControl
                                className="form_control w-100"
                                fullWidth
                              >
                                <Typography component="label">
                                  Pay Period Unit
                                </Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="Suppliers"
                                >
                                  <MenuItem value="Suppliers">Select</MenuItem>
                                  <MenuItem value="car">
                                    Per Day / 8 Hours
                                  </MenuItem>
                                  <MenuItem value="van">
                                    Per Week / 2 Days
                                  </MenuItem>
                                  <MenuItem value="jeep">
                                    Per Month / 26 Days
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                              <FormControl
                                className="form_control w-100"
                                fullWidth
                              >
                                <Typography component="label">
                                  Base Pay
                                </Typography>
                                <OutlinedInput
                                  placeholder="Base Pay"
                                  required
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={payenginet1columns}
                                options={itemOptions}
                                data={rolesdata}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={7}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              SERVICES
                            </Typography>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Service Type
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Delivery Boy
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Assistant
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Shop Keeper
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Accountant
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={6}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Shared
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Single
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={servicecolumns}
                                options={itemOptions}
                                data={servicedata}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddEmployeeModal ref={ref4} />
        <ManagementViewMoreModal ref={ref1} />
      </Box>
    </>
  );
}
